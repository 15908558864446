import React from "react";

import { useTranslation } from "react-i18next";
import moment from "moment";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";

import { makeStyles } from "@mui/styles";

import { ObjectType } from "../../types/tableTypes";
import InforErrorIcon from "../../assets/InfoErrorIcon.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { isFalsyButNotZero } from "../../utils/Validations";

import "./CustomCard.css";
import { getCookie } from "../../utils/Common";

const useStyles = makeStyles({
  card: {
    alignItems: "center",
    alignContent: "center",
    padding: "0px",
  },
  testCard: {
    borderRadius: "6px !important;",
    backgroundColor: "#F7F7F7 !important;",
  },
});
  
export default function CustomCard({
  headingText,
  subHeadingText,
  loanDetails,
  subComponents,
  fullView,
  ratesAsOfDate,
  data,
  gridSpace,
  cardCss,
  headerClass,
  showFailed = false,
  background,
  icon,
  textMaxWidth
}: {
  headingText: any;
  subHeadingText: string;
  loanDetails: ObjectType;
  fullView: boolean;
  ratesAsOfDate: string | undefined;
  subComponents?: any;
  data?: ObjectType;
  gridSpace?: number;
  cardCss?: boolean;
  headerClass?: string;
  showFailed?: boolean;
  background?: string;
  icon?: any;
  textMaxWidth?: any;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const newRatesAsOfDate = loanDetails?.isApplicationSubmitted
    ? loanDetails?.ratesAsOf
    : ratesAsOfDate || loanDetails?.ratesAsOf;

  const orgFundingType =
    loanDetails?.fundingType || getCookie("orgFundingType");

  let finalRateSubText: string = t("finalRateSubText");
  const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})/;
  finalRateSubText = finalRateSubText.replace(
    emailRegex,
    '<a href="mailto:$1">$1</a>'
  );

  const IconToBeShown = icon ? icon : InforErrorIcon;
  let cardClass = classes.card;
  if (cardCss) {
    cardClass += ` ${classes.testCard}`;
  }

  function getValue(value: any, type: string) {
    switch (type) {
      case "percent":
        return value ? `${value} %` : "0.00 %";
    }
  }

  return (
    // <Grid item xs={fullView ? 12 : gridSpace} style={{'border': '1px solid green'}}>
    <Card
      className={classes.card}
      sx={
        showFailed
          ? {
              marginBottom: "12px",
              background: background ?? "#F7F7F7",
            }
          : {}
      }
      data-testid="custom-card-div"
    >
      <CardContent
        style={{
          height: typeof headingText === "string" ? "auto" : "",
          display: typeof headingText === "string" ? "block" : "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "8px",
        }}
      >
        {headingText === "Leverage Adjustment" &&
        !data?.isLeverageAdjustment ? (
          <h3 className="noAdjustementText align-text-center" style={{textAlign: 'center'}}>
            {t("noLeveragesAdjustments")}
          </h3>
        ) : (
          <div>
            <div className={headerClass} style={{ marginBottom: "4px" }}>
              <h3
                data-testid="custom-card-heading"
                className={
                  ["Final Price", "Proceeds"].includes(headingText)
                    ? "headerText"
                    : "headerText align-text-center"
                }
                style={
                  showFailed
                    ? { textAlign: "start", margin: "4px" }
                    : { margin: "4px"}
                }
              >
                {showFailed ? (
                  <img
                    src={IconToBeShown}
                    alt="info-error.svg"
                    className="mr-24"
                  />
                ) : null}
                {headingText}
              </h3>
              {data?.finalPriceRate ? (
                <span className="final-rate-price-text">
                  {isFalsyButNotZero(data?.finalPriceRate)
                    ? `${data?.finalPriceRate} %`
                    : ""}
                </span>
              ) : null}
            </div>
            {["Final Price"].includes(headingText) && loanDetails?.ratesAsOf ? (
              <div className="mt-16">
                <span className="rates-text">
                  {t("ratesAsOfDate")} &nbsp;
                  <b>
                    {moment(newRatesAsOfDate, "DD-MM-YYYY").format(
                      "MMM DD, YYYY"
                    )}
                  </b>
                </span>
              </div>
            ) : null}
            {subHeadingText || data?.isLeverageAdjustment ? (
              <div
                style={
                  typeof headingText === "string" ? {
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column"
                  } : { maxWidth: textMaxWidth ?? subHeadingText === "Final Buy Rate" ? "auto" : "", display: "flex", justifyContent: "center", flexDirection: "column" }
                }
              >
                <span
                  className={`subHeadingText ${
                    showFailed ? "text-align-left" : ""
                  }`}
                  style={{ margin: "0px", textAlign: 'center' }}
                >
                  {subHeadingText}
                </span>
                {data?.isLeverageAdjustment ? (
                  <div className="mb-12 w-100">
                    <Grid container className="mb-12">
                      <Grid item xs={6}>
                        <span
                          className={
                            data?.totalAdjustments < 0
                              ? "error-text fs-20"
                              : "fs-20"
                          }
                        >
                          {getValue(
                            data?.totalAdjustments?.toFixed(2),
                            "percent"
                          )}
                        </span>
                        <br />
                        <br />
                        {t("totalAdjustments")}
                      </Grid>
                      <Grid item xs={6}>
                        <span>{t("totalNegative")}</span> &ensp;
                        <span
                          className={
                            data?.totalAdjustments < 0 ? "error-text" : ""
                          }
                        >
                          {getValue(data?.totalNegative?.toFixed(4), "percent")}
                        </span>
                        <br />
                        <span>{t("totalPositive")}</span> &ensp;
                        <span>{getValue(data.totalPositive, "percent")}</span>
                      </Grid>
                    </Grid>
                    <Divider />
                  </div>
                ) : null}
                {subHeadingText === "Note Rate (%)" &&  orgFundingType == "table_funding" ? (<>
                    <div style={{ background: "#00000005", display: 'flex', marginTop: "4px" }}>
                      <InfoOutlinedIcon
                        style={{ fontSize: "14px", marginTop: "2px" }}
                      />
                      &nbsp;
                      <span style={{ fontSize: "12px" }}>
                        {t("noteRateSubText")}
                      </span>
                  </div>
                    </>) : null}
                {data?.finalRate &&
                subHeadingText === "Final Buy Rate" &&
                orgFundingType == "table_funding" ? (
                  <div style={{ background: "#00000005", display: 'flex' }}>
                    <InfoOutlinedIcon
                      style={{ fontSize: "14px", marginTop: "2px" }}
                    />
                    &nbsp;
                    <div
                      dangerouslySetInnerHTML={{ __html: finalRateSubText }}
                      style={{ fontSize: "12px", lineBreak: "anywhere" }}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {subComponents}
          </div>
        )}
      </CardContent>
    </Card>
    // </Grid>
  );
}
