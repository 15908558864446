/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import jwt_decode from "jwt-decode";

import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Disclaimer from "../../components/common/Disclaimer";
import { CustomTable } from "@toorak/tc-common-fe-sdk";
import {
  newStyles,
  overRideStyles,
} from "../../components/common/Table/TableStyle";
import { TableColumns } from "../../components/common/Table/TableColumns";

import DownArrow from "../../assets/DownArrow.svg";
import UpArrow from "../../assets/UpArrow.svg";
import EmptyLoan from "../../assets/EmptyLoan.svg";

import {
  filterKeyOrgMap as orgMap,
  filterKeyLatMap as latMap,
  filterKeyILPMap as iLPMap,
  filterKeyApraisalMap as appraisalMap,
  isILP,
  sanitizeData,
  getCookie,
  sanitizePercentage,
} from "../../utils/Common";

import { ObjectType } from "../../types/tableTypes";

import { RootContext } from "../../context/RootContext";

import "./listView.css";
import { filterDataResponse, postRowData } from "../../services/network";
import { SizingTypeValue } from "../../types/enums";
import { tablePaginationClasses as classes } from "@mui/material/TablePagination";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import TablePagination from "@mui/material/TablePagination";
import { styled } from "@mui/system";
import { blue, grey } from "@mui/material/colors";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Tab, TableContainer, Tabs } from "@mui/material";

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .${classes.spacer} {
    display: none;
  }

  & .${classes.toolbar}  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.select}{
    padding: 4px;
    border: none;
    background-color: transparent;
  }

  & .${classes.displayedRows} {
    display: none;
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.actions} {
    display: none;
    margin-left: 0px !important;
    padding: 2px;
    border-radius: 50px;
    text-align: center;
  }

  & .${classes.actions} > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;

    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    }

    &:focus {
      outline: 1px solid ${
        theme.palette.mode === "dark" ? blue[400] : blue[200]
      };
    }
  }
  `
);

const CustomPaginationItem = styled(PaginationItem)(
  () => `
  &.MuiPaginationItem-rounded.Mui-selected {
    background-color: #362866 !important;
    color: #ffffff;
  }`
);

function ListView() {
  const {
    setDynamicLoader,
    showTermSheet,
    setCurrentPath,
    setTermSheetAction,
    setTermSheetData,
    dynamicConfig,
  } = useContext(RootContext);

  const userPermissions = useMemo(() => {
    return JSON.parse(getCookie("userPermissions") || "[]");
  }, []);

  const isLoanOfficer = JSON.parse(
    getCookie("userPermissions") || "[]"
  )?.includes("loan_officer");

  const [isCollapsed, setCollapsed] = useState<boolean>(false);
  const [filtersLoader, setFiltersLoader] = useState({});
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [loanDetailsSelected, setLoanDetailsSelected] = useState<any[]>([]);
  const [unselectedLoanIds, setUnselectedLoanIds] = useState<string[]>([]);
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [copyValueArray, setCopyValueArrayState] = useState<any>([]);
  const [rowsData, setRowsData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("");
  const [newestLoanArrow, setNewestLoanArrow] = useState<boolean>(false);
  const [selectedLoanType, setSelectedLoanType] = useState<any>([]);
  const [dateSelected, setDateSelected] = useState("");
  const [newTableColumns, setNewTableColumns] = useState<any[]>(TableColumns);
  const [filtersOptions, setFiltersOptions] = useState<any>({});
  const [selectedSizingType, setSelectedSizingType] = useState<any>(0);
  const sizingTabsList = [
    SizingTypeValue.QUICK_LOAN_SIZING,
    SizingTypeValue.DETAILED_LOAN_SIZING,
  ];

  const checkboxRef: any = useRef([]);
  const { t } = useTranslation();
  const classes: any = newStyles();
  const overRidenStyles = overRideStyles();
  const navigate = useNavigate();

  const sortConfig: any = {};

  const accessToken: string = getCookie("sizer_access_token") || "";
  const decodedData: ObjectType = jwt_decode(accessToken);

  const onSort = useCallback((config: any) => {
    const columnKey = Object.keys(config)?.[0];
    const val = config?.[columnKey];
  }, []);

  const handleFilterToggle = useCallback((columnName: string) => {
    if (columnName === "waiverStatus") {
      const waiverStatusFilterOptions = [
        { key: "Requested", docCount: Math.random() },
        { key: "Not-Requested", docCount: Math.random() },
        { key: "Approved", docCount: Math.random() },
        { key: "Rejected", docCount: Math.random() },
      ];
      setFiltersOptions((previous: any) => {
        return {
          ...previous,
          [columnName]: waiverStatusFilterOptions,
        };
      });
    } else {
      setFiltersLoader((filtersLoader) => ({
        ...filtersLoader,
        [columnName]: true,
      }));
      getFilterData(columnName);
    }
  }, []);

  const findLatestGeneratedTermsheet = (data: any[], key: string) => {
    if (!data || data?.length === 0) {
      return null;
    }
    if (data?.length === 1) {
      return data[0]?.emailSentOn ? data[0] : null;
    }

    const latestElements = data
      .sort((a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime())
      .slice(0, 2);

    return latestElements[0]?.emailSentOn
      ? latestElements[0]
      : latestElements[1];
  };

  const handleSizingTypeChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setSelectedSizingType(newValue);
  };

  useEffect(() => {
    setTermSheetAction("");
    setTermSheetData(null);
    setNewTableColumns(
      TableColumns.filter(
        (e: any) =>
          !e?.hidden?.({
            termSheet: showTermSheet,
            merchants: dynamicConfig?.ViteAppKcRealm.includes("merchants"),
            sizingType: sizingTabsList[selectedSizingType],
            isLoanOfficer,
          })
      )
    );
    setCurrentPath(window.location.pathname);
  }, [showTermSheet, selectedSizingType, isLoanOfficer]);

  useEffect(() => {
    if (userPermissions.includes("loan_processor")) {
      setSelectedSizingType(1);
    } else {
      setSelectedSizingType(0);
    }
  }, [userPermissions]);

  const [tableFilterConfig, setTableFilterConfig] = useState<any>({});

  const filterKeyMap: ObjectType = useMemo(
    () => ({
      ...orgMap,
      ...latMap,
      ...iLPMap,
      ...appraisalMap,
    }),
    []
  );

  const tableSearchConfig = useMemo(() => {
    const filters = tableFilterConfig?.filterSelections ?? {};
    const filterKeys = Object.keys(filters);
    let tableSearchFilters = {};
    filterKeys.forEach((key: string) => {
      if (key.includes("_searchBox")) {
        const filterValue = filters?.[key].join();
        tableSearchFilters = {
          ...tableSearchFilters,
          [key.replace("_searchBox", "")]: filterValue,
        };
      }
    });
    return tableSearchFilters;
  }, [tableFilterConfig]);

  const tableSortConfig = useMemo(() => {
    return { [sortConfig?.sortColumn]: sortConfig?.sort };
  }, [sortConfig]);

  const isSingleLoanTypeSelected = useMemo(() => {
    return tableFilterConfig?.filterSelections?.["loanType"]?.length === 1;
  }, [tableFilterConfig]);

  function handleSearch(e: React.FormEvent) {
    e.preventDefault();
    setCurrentPage(1);
    getRowData(1);
  }

  function noApplicationData() {
    return (
      <>
        <Grid container>
          <Grid item xs={4}></Grid>
          <Grid item xs={8}>
            <img src={EmptyLoan} alt="empty-loan" />
          </Grid>
        </Grid>
        <Grid container className="mb-40">
          <Grid item xs={4}></Grid>
          <Grid item xs={8}>
            <h2>{t("noApplicationText")}</h2>
          </Grid>
        </Grid>
        <Grid container className="mb-40">
          <Grid item xs={5}></Grid>
          <Grid item xs={6}>
            <Button
              className="mr-12"
              variant="contained"
              onClick={openEvaluateLoan}
              data-testid="evaluate-new-loan-button"
            >
              {t("evaluateNewLoan")}
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <Footer showFooterText={true} />
          </Grid>
        </Grid>
      </>
    );
  }

  const handleCheckBoxChange = useCallback(
    (config: any) => {
      const dynamicKey = Object.keys(config)[0];
      config[Object.keys(config)[0]] = config[Object.keys(config)[0]].filter(
        (val: string) => val !== "Select All"
      );
      let updatedSelectedFiltersConfig = {};
      if (config?.[dynamicKey]?.length) {
        updatedSelectedFiltersConfig = {
          ...tableFilterConfig,
          ...config,
        };
      } else {
        const filters: any = { ...tableFilterConfig };
        delete filters[dynamicKey];
        updatedSelectedFiltersConfig = {
          ...filters,
        };
      }
      setTableFilterConfig(updatedSelectedFiltersConfig);
    },
    [tableFilterConfig]
  );

  const PrevButton = () => {
    return (
      <Button startIcon={<NavigateBeforeIcon />} data-testid="previous-button">
        Previous
      </Button>
    );
  };

  const NextButton = () => {
    return (
      <Button endIcon={<NavigateNextIcon />} data-testid="next-button">
        Next
      </Button>
    );
  };

  const applicationTable = () => {
    return (
      <>
        <Grid container>
          <Disclaimer
            boldText={t("elNoteText")}
            children={<span>{t("evaluateLoanDisclaimer")}</span>}
            customClass="disclaimerBox"
            showClose={true}
          />
          {dynamicConfig?.showSizingType ? (
            <Grid item xs={12} className="sizing-tab">
              <Tabs
                defaultValue={0}
                value={selectedSizingType}
                onChange={handleSizingTypeChange}
              >
                {sizingTabsList?.map((sizingType: string, idx: number) => {
                  return (
                    <Tab
                      key={idx}
                      label={
                        <span
                          className={
                            selectedSizingType === idx
                              ? "active-list-tab"
                              : "inactive-list-tab"
                          }
                        >
                          {sizingType}
                        </span>
                      }
                    />
                  );
                })}
              </Tabs>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6}>
            <h3>
              {t("applications")}
              {totalDataCount}
            </h3>
          </Grid>
          <Grid item xs={12} sm={6} className="div-end">
            <p>{t("newestFirst")}</p>
            <IconButton
              sx={{
                "&:hover": { backgroundColor: "transparent" },
              }}
              onClick={() => {
                handleSortArrow();
              }}
              data-testid="arrow-icon"
            >
              <img
                src={newestLoanArrow ? DownArrow : UpArrow}
                alt="arrow.svg"
              />
            </IconButton>
          </Grid>
        </Grid>

        <TableContainer
          className={classes?.customScrollbar}
          sx={{ width: "100%" }}
        >
          <CustomTable
            columnsData={newTableColumns}
            rowsData={rowsData}
            classes={classes}
            isCollapsed={isCollapsed}
            filtersLoader={filtersLoader}
            tableFilterConfig={tableFilterConfig}
            tableSearchConfig={tableSearchConfig}
            tableSortConfig={tableSortConfig}
            filtersOptions={filtersOptions}
            onSort={onSort}
            handleFilterToggle={handleFilterToggle}
            handleCheckBoxChange={handleCheckBoxChange}
            handleDateRangeFilter={handleDateRangeFilter}
            expandColumnIndex={0}
            showNoDataMsg
            overRideStyles={{
              ...overRidenStyles,
              filterBoxStyle: {
                right: "0px",
                left: "auto",
                top: "40px",
              },
            }}
            onSelectedColor="#F6FBFF"
            showCheckBox={false}
            allSelected={allRowsSelected && unselectedLoanIds.length === 0}
            selectedLoans={selectedRows}
            fewRowsSelected={
              unselectedLoanIds?.length > 0 && selectedRows?.length > 0
            }
            disableSelectAll={isILP() ? false : !isSingleLoanTypeSelected}
            selectAllTootltipText={
              isSingleLoanTypeSelected || isILP()
                ? ""
                : "Select a loan type to export loans"
            }
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            totalDataCount={totalDataCount}
            onLinkClick={onLinkClick}
            getRowData={getRowData}
            handleCheckboxOnchange={handleCheckboxOnchange}
            selectedLoanType={selectedLoanType}
            uncheckAll={uncheckAll}
            checkboxRef={checkboxRef}
            handleRatesAsOfFilter={handleRatesAsOfFilter}
            dateSelected={dateSelected}
            setDateSelected={setDateSelected}
          />
        </TableContainer>

        <Grid>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            marginBottom={dynamicConfig?.ViteAppKcRealm === "bplsizer" ? 3 : 0}
          >
            <Grid item xs={12} sm={6}>
              <CustomTablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={totalDataCount}
                rowsPerPage={rowsPerPage}
                colSpan={3}
                page={currentPage}
                onPageChange={(event: unknown, newPage: number) =>
                  setCurrentPage(newPage)
                }
                onRowsPerPageChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setCurrentPage(1);
                }}
                style={{ borderBottom: "none" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Pagination
                count={Math.ceil(totalDataCount / rowsPerPage)}
                page={currentPage}
                onChange={(event: unknown, newPage: number) =>
                  setCurrentPage(newPage)
                }
                renderItem={(item) => (
                  <CustomPaginationItem
                    {...item}
                    shape="rounded"
                    slots={{ previous: PrevButton, next: NextButton }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const handleCheckboxOnchange = (name: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedLoanType((prevItems: any) => [...prevItems, name]);
    } else {
      setSelectedLoanType((prevItems: any) =>
        prevItems.filter((item: any) => item !== name)
      );
    }
  };

  const handleRatesAsOfFilter = (selectedDate: any) => {
    if (selectedDate) {
      const formattedDate = moment(selectedDate).format("MM-DD-YYYY");
      setDateSelected(formattedDate);
    }
  };

  const uncheckAll = () => {
    const checkboxes = checkboxRef?.current.filter(
      (item: any) => item !== null
    );
    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = false;
    });
    setSelectedLoanType([]);
  };

  const filterType = {
    loanType: "select",
    productType: "select",
    ratesAsOf: "dateRange",
    sizingType: "select",
    waiverStatus: "select",
    createdBy: "select",
  };

  const getRowData = async (page: any = currentPage, sortBy?: any) => {
    setDynamicLoader(true);
    try {
      const sortValue = newestLoanArrow ? "asc" : "desc";
      const sortBy = [
        {
          key: "createdAt",
          sort: sortValue,
        },
      ];
      let value: any = searchText;
      let key = "data.applicationName";
      if (!isNaN(+searchText)) {
        key = "data.evaluationID";
        value = searchText;
      }

      const searchKey = {
        key,
        value,
      };

      let filters: any = {
        "data.applicationName": { $exists: true },
        "data.evaluationID": { $exists: true },
      };
      if (isLoanOfficer) {
        filters = {
          ...filters,
          createdBy: decodedData?.name,
        };
      } else {
        filters = {
          ...filters,
          "data.orgId": getCookie("orgId"),
        };
      }
      let ratesFilter;

      if (dateSelected) {
        ratesFilter = {
          "data.ratesAsOf": moment(dateSelected, "MM-DD-YYYY").format(
            "DD-MM-YYYY"
          ),
        };
      }

      if (dynamicConfig?.showSizingType) {
        filters = {
          ...filters,
          "data.sizingType": sizingTabsList[selectedSizingType],
        };
      }

      if (Object?.keys(tableFilterConfig)?.length) {
        const newFilters = generateFilterQuery(tableFilterConfig, filterType);
        filters = {
          ...filters,
          ...newFilters,
        };
      }

      if (ratesFilter) {
        filters = {
          ...filters,
          ...ratesFilter,
        };
      }

      const body: ObjectType = {
        page,
        limit: rowsPerPage,
        sortBy,
        filters,
      };

      if (searchText.length) {
        body.search = searchKey;
      }

      const { data } = await postRowData(body);

      const tempResult: any = [];
      data?.data?.records.map((data: ObjectType) => {
        const currentDataObj =
          data?.data?.sizingType === SizingTypeValue?.DETAILED_LOAN_SIZING
            ? data?.data?.detailedLoanSizingData ?? data?.data
            : data?.data?.sizingType === SizingTypeValue?.QUICK_LOAN_SIZING
            ? data?.data?.quickLoanSizingData ?? data?.data
            : data?.data;
        const formattedCreatedOn = data?.createdAt
          ? moment(new Date(data.createdAt)).format("MM/DD/YYYY")
          : "";
        let ratesAsOf = data?.data?.ratesAsOf?.replace(/[a-zA-Z]/g, "") ?? "";
        ratesAsOf = ratesAsOf
          ? moment(ratesAsOf, "DD-MM-YYYY").format("MM/DD/YYYY")
          : "";
        const currentProperty = Array.isArray(currentDataObj?.propertyDetails)
          ? currentDataObj?.propertyDetails[0]
          : {
              propertyLocation: currentDataObj?.propertyLocation,
            };
        const termSheetVersions = data?.termSheetVersions?.filter((el: any) => el?.emailSentOn)
        const termSheet =
          termSheetVersions?.length &&
          findLatestGeneratedTermsheet(termSheetVersions, "createdAt");
        const interestRate =
          (currentDataObj?.currentSelectedInterestRate?.interestRate ||
            currentDataObj?.loanEconomics?.interestRate ||
            currentDataObj?.loanEconomics?.originalInterestRate) ??
          null;
        tempResult.push({
          evaluationId: data?.data?.evaluationID || "",
          applicationName: data?.data?.applicationName || null,
          loanType: data?.data?.productType,
          loanAmount: sanitizeData(
            (currentDataObj?.loanEconomics?.originalLoanAmount ||
              currentDataObj?.loanEconomics?.initialLoanAmount ||
              currentDataObj?.loanEconomics?.borrowerRequested ||
              currentDataObj?.loanInformation?.borrowerRequested) ??
              null,
            true
          ),
          interestRate: interestRate ? sanitizePercentage(interestRate) : null,
          createdOn: formattedCreatedOn,
          createdBy: data.createdBy,
          ratesAsOf,
          streetAddress: currentProperty?.propertyLocation?.address,
          city:
            currentProperty?.propertyLocation?.city ??
            currentDataObj?.propertyLocation?.city,
          postal:
            currentProperty?.propertyLocation?.postalCode ??
            currentDataObj?.propertyLocation?.city,
          loanId: data?.loanId,
          productType: data?.data?.loanType,
          termSheet: termSheet?.termSheetVersion
            ? `${t("termSheetV")}${termSheet?.termSheetVersion}`
            : "Not Generated",
          termSheetVersion: termSheet?.termSheetVersion,
          termSheetName: termSheet?.termSheetName,
          sizingType: data?.data?.sizingType ?? "--",
          waiverStatus: data?.data?.waiverStatus ?? "Not-Requested",
        });
      });
      setTotalDataCount(data?.data?.total);
      setRowsData(tempResult);
    } catch (err) {
      console.error(err);
    } finally {
      setDynamicLoader(false);
    }
  };

  const openEvaluateLoan = () => {
    navigate("/evaluate-loan");
  };

  useEffect(() => {
    getRowData(currentPage);
    setCurrentPath(window.location.pathname);
  }, [
    currentPage,
    newestLoanArrow,
    rowsPerPage,
    tableFilterConfig,
    selectedSizingType,
    isLoanOfficer,
  ]);

  const onLinkClick = (loanType: any, loanId: any, productType?: any) => {
    const redirectLoanType =
      loanType === "Bridge Loan"
        ? `${loanType}/${productType}`.replace(/ +/g, "")
        : loanType.replace(/ +/g, "");
    return `${window.location.origin}/loan/${redirectLoanType}/${loanId}`;
  };

  const handleSortArrow = () => {
    setNewestLoanArrow(!newestLoanArrow);
  };

  const getFilterData = (columnName: string = "productType") => {
    let filters: any = {
      "data.applicationName": { $exists: true },
      "data.evaluationID": { $exists: true },
    };
    if (isLoanOfficer) {
      filters = {
        ...filters,
        createdBy: decodedData?.name,
      };
    } else {
      filters = {
        ...filters,
        "data.orgId": getCookie("orgId"),
      };
    }
    filterDataResponse(filters, columnName, columnName !== "createdBy")
      .then((res: any) => {
        const fRes: any = [];
        if (res?.data?.data) {
          Object.values(res.data.data).forEach((data: any) => {
            if (data._id) {
              fRes.push({ key: data._id, docCount: Math.random() });
            }
          });
          setFiltersOptions((previous: any) => {
            return {
              ...previous,
              [columnName]: fRes,
            };
          });
          setFiltersLoader((filtersLoader) => ({
            ...filtersLoader,
            [columnName]: false,
          }));
        }
      })
      .catch((err: ObjectType) => {
        console.error(err);
      });
  };

  function generateFilterQuery(params: any, filterType: any) {
    const filterQuery: any = { $and: [] };
    for (const [key, value] of Object.entries(params)) {
      const mappedKey = key !== "createdBy" ? `data.${key}` : key;
      const type = filterType[key];
      if (type) {
        const filteredValues = Array.isArray(value)
          ? value.filter((val) => val !== "Select All")
          : value;

        if (type === "dateRange" && Array.isArray(filteredValues)) {
          const dateRange = filteredValues[0];
          if (dateRange?.gte && dateRange?.lte) {
            filterQuery.$and.push({
              [mappedKey]: {
                $gte: moment(dateRange.gte, "YYYY-MM-DD").format("DD-MM-YYYY"),
                $lte: moment(dateRange.lte).format("DD-MM-YYYY"),
              },
            });
          }
        } else if (
          type === "select" &&
          Array.isArray(filteredValues) &&
          filteredValues.length > 0
        ) {
          filterQuery.$and.push({
            $or: filteredValues.map((val) => ({
              [mappedKey]: val === "Not-Requested" ? { $exists: false } : val,
            })),
          });
        }
      }
    }

    return filterQuery;
  }

  const handleDateRangeFilter = useCallback(
    (config: any) => {
      const colName = Object.keys(config)[0];
      const isColFiltered = config?.[colName] ?? [];
      const filterselections: any = [...isColFiltered];

      const currentSelections = { ...tableFilterConfig };
      if (!filterselections.length) {
        delete currentSelections?.[colName];
        setTableFilterConfig(currentSelections);
      } else {
        filterselections.range = true;
        const updatedSelectedFiltersConfig = {
          ...tableFilterConfig,
          [colName]: filterselections,
        };
        setTableFilterConfig(updatedSelectedFiltersConfig);
      }
    },
    [tableFilterConfig]
  );

  return (
    <div>
      <Header
        showEvaluateNewLoan={
          rowsData.length ||
          totalDataCount ||
          !selectedLoanType.length ||
          !dateSelected
        }
        showSearch={true}
        showBorder={true}
        searchText={searchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
      />
      {rowsData.length ||
      totalDataCount ||
      !selectedLoanType.length ||
      !dateSelected
        ? applicationTable()
        : noApplicationData()}
    </div>
  );
}

export default ListView;
