import { Button, Grid } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useReactToPrint } from "react-to-print";
import { useTranslation } from 'react-i18next';
import { LoansContext } from '../../context/LoansContext';
import CloseIcon from '../../assets/Close-Icon-lg.svg'
import InfoIcon from '../../assets/InfoIconBlack.svg'
import ToorakLogo from '../../assets/Toorak_Logo.svg'
import ExportIcon from '../../assets/ExportIcon.svg'
import KDMModal from './KDMModal';
import { useParams } from 'react-router-dom';
import { formatValueByType } from '../../utils/Common';
import { orgBucketResponse } from '../../services/network';
import { SizingTypeValue } from '../../types/enums';

interface KDMWrapperProps {
  openGoogleMap?: (label: string) => void;
}


type ParamType = {
  loanType: string | undefined;
  loanId: string | undefined;
  propType: string | undefined;
};

const KDMWrapper = (props: KDMWrapperProps) => {
  const { t } = useTranslation();
  const loanContextData = useContext(LoansContext);
  const [showKDMBody, setShowKDMBody] = useState<boolean>(false)
  const [originatorInfo, setOriginatorInfo] = useState<any>(null)
  const [data, setData] = React.useState<{
    loanDetails: any;
    propertyDetails: any;
    ruleResult: any;
    propertiesResults: any;
    loanStage: string;
    loanType: string | undefined;
    originatorInfo: any;
    selectedBuyDownOptions: any;
    leverageAndPricingData: any
  } | null>(null);

  const params = useParams<ParamType>();
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({
    contentRef
  });

  const { loanType, loanId } = params;

  const handleShowKDMBody = (status: boolean) => {
    setShowKDMBody(status)
  }

  const openGoogleMap = (label: string) => {
    const query = `http://maps.google.com/?q=${label}`;
    window.open(query);
  };

  useEffect(() => {
    if (loanId) {
      init(loanId)
    }

  }, [
      loanContextData, 
      loanId, 
      originatorInfo,
      loanContextData?.loanDetails,
      loanContextData?.sizingType,
      loanContextData?.currentSelectedInterestRate      
    ])

  const init = async (loanId: string) => {
    let originator: any = originatorInfo ?? null
    const assignedPartner = loanContextData?.assignedPartner
    const rulesResponse = loanContextData?.rulesResponse ? loanContextData?.rulesResponse || {} : loanContextData?.loanDetails?.rulesResponse || {}
    const loanResultData =
      rulesResponse?.tpResultMap?.[assignedPartner]
        ? rulesResponse?.tpResultMap?.[assignedPartner]?.loanResult?.[loanId]
        : rulesResponse?.loanResult
          ? rulesResponse?.loanResult[loanId]
          : rulesResponse ? rulesResponse?.[assignedPartner ? assignedPartner : Object.keys(rulesResponse)[0]]?.loanResult?.[loanId] : {}
    const propertiesResults = rulesResponse?.tpResultMap?.[assignedPartner]?.propertiesResults?.["1"]
      ? rulesResponse?.tpResultMap?.[assignedPartner]?.propertiesResults?.["1"]
      : rulesResponse?.propertiesResults ? rulesResponse?.propertiesResults?.["0"] : rulesResponse ? rulesResponse?.[assignedPartner ? assignedPartner : Object.keys(rulesResponse)[0]]?.propertiesResults?.["1"] : {}
    if (loanContextData?.loanDetails?.orgId && !originator) {
      const response = await orgBucketResponse(loanContextData?.loanDetails?.orgId)
      if (response?.data?.accounts?.length) {
        originator = response?.data?.accounts?.find((ele: any) => ele?.partyId === loanContextData?.loanDetails?.orgId)
      }
      if (!originator && response?.data?.customers?.length) {
        originator = response?.data?.customers?.find((ele: any) => ele?.partyId === loanContextData?.loanDetails?.orgId)
      }
    }
    setOriginatorInfo(originator)
    let newDataToBeReffered: any = {
      ...loanContextData?.loanDetails,
    };
    if (newDataToBeReffered?.sizingType === SizingTypeValue?.QUICK_LOAN_SIZING) {
      newDataToBeReffered = {
        ...newDataToBeReffered,
        ...loanContextData?.quickLoanSizingData,
      };
    } else if (
      newDataToBeReffered?.sizingType === SizingTypeValue?.DETAILED_LOAN_SIZING
    ) {
      newDataToBeReffered = {
        ...newDataToBeReffered,
        ...loanContextData?.detailedLoanSizingData,
      };
    }
    const propertyDetails = loanContextData?.propertyDetails?.length ? loanContextData?.propertyDetails : newDataToBeReffered?.propertyDetails

    const currentSelectedInterestRate =
      newDataToBeReffered?.currentSelectedInterestRate ?? loanContextData?.currentSelectedInterestRate
    const buyDownOptions =
      loanResultData?.buyDownOptions;
    const selectedBuyDownOptions = buyDownOptions?.find((item: any) => {
      const itemInterestRate = item?.interestRate
        ? parseFloat(item.interestRate) * 100
        : null;
      const selectedInterestRate = currentSelectedInterestRate?.interestRate
        ? parseFloat(currentSelectedInterestRate?.interestRate.replace("%", ""))
        : null;

      if (itemInterestRate === null || selectedInterestRate === null) {
        return false;
      }
      return itemInterestRate.toFixed(4) === selectedInterestRate.toFixed(4);
    });
    setData({
      loanDetails: newDataToBeReffered,
      propertyDetails,
      ruleResult: loanResultData,
      propertiesResults: propertiesResults,
      loanStage: "FES",
      loanType,
      originatorInfo: originator,
      selectedBuyDownOptions,
      leverageAndPricingData: loanContextData?.leverageAndPricingData
    });
  }

  return (
    <div>
      <Grid item className="gray-border">
        <Button
          className="mr-12"
          variant="outlined"
          onClick={() => handleShowKDMBody(true)}
          data-testid="key-deal-metrics-button"
        >
          {t("kdm")}
        </Button>
        {showKDMBody ?
          <KDMModal
            header={t("kdm")}
            handleShowKDMBody={handleShowKDMBody}
            icons={{ CloseIcon, InfoIcon, ToorakLogo, ExportIcon }}
            data={data}
            config={{
              formatValueByType: formatValueByType,
              openGoogleMap: openGoogleMap,
              componentRef: contentRef,
              reactToPrintFn: reactToPrintFn,
              loanId: loanId
            }}
          />
          : null}
      </Grid>
    </div>
  )
}

export default KDMWrapper
