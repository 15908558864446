import { useContext, useEffect, useState } from "react";
import { t } from "i18next";

import { LoansContext } from "../../../../../../context/LoansContext";

import BuyDownOptions from "./BuyDownOptions";

import { formatCurrency } from "../../../../../../utils/v2/common";
import { SizingTypeValue } from "../../../../../../types/enums";

export default function BuyDownOptionsWrapper(props: any) {
  const { handleSaveApplication } = props;

  const {
    buyDownOptions,
    currentSelectedInterestRate,
    setCurrentSelectedInterestRate,
    loanDetails,
    setQuickLoanSizingData,
    quickLoanSizingData,
    setDetailedLoanSizingData,
    detailedLoanSizingData,
    isApplicationSubmitted
  } = useContext(LoansContext);

  const [formattedBuyDownOptionsState, setFormattedBuyDownOptionsState] =
    useState([]);

  const sortInterestRates = (data: Array<any>) => {
    return data?.sort((a, b) => {
      const rateA = parseFloat(a.interestRate.replace("%", ""));
      const rateB = parseFloat(b.interestRate.replace("%", ""));
      return rateA - rateB;
    });
  };

  const sortDiscountPointPercentage = (data: Array<any>) => {
    return data?.sort((a, b) => {
      const rateA = parseFloat(a.discountPointPercentage);
      const rateB = parseFloat(b.discountPointPercentage);
      return rateA - rateB;
    });
  };

  useEffect(() => {
    const findCurrentSelection = buyDownOptions?.find(
      (option: any) =>
        option.interestRate ===
        Number(
          currentSelectedInterestRate?.interestRate?.replace("%", "") / 100
        )
    );
    const sortedBuydownOptions = sortDiscountPointPercentage(buyDownOptions);
    let formattedBuyDownOptions: any = [];
    sortedBuydownOptions?.forEach((option: any, index : number) => {
      if (index === 0) {
        if (!findCurrentSelection) {
          setCurrentSelectedInterestRate({
            interestRate: `${(option?.interestRate * 100)?.toFixed(3)}%`,
            discountPoints: `${option?.discountPointPercentage?.toFixed(4)}`,
            discountPointsWithDollar: formatCurrency(
              option?.discountPointAmount
            ),
            totalBuyYield: option?.totalBuyYield,
          });
        } else {
          setCurrentSelectedInterestRate({
            interestRate: currentSelectedInterestRate?.interestRate,
            discountPoints: currentSelectedInterestRate?.discountPoints,
            discountPointsWithDollar:
              currentSelectedInterestRate?.discountPointsWithDollar,
            totalBuyYield: currentSelectedInterestRate?.totalBuyYield,
          });
        }
        if (loanDetails?.sizingType === SizingTypeValue.QUICK_LOAN_SIZING) {
          setQuickLoanSizingData({
            ...quickLoanSizingData,
            loanEconomics: {
              ...quickLoanSizingData?.loanEconomics,
              ["interestRate"]: (option?.interestRate * 100).toFixed(3),
            },
          });
        } else {
          setDetailedLoanSizingData({
            ...detailedLoanSizingData,
            loanEconomics: {
              ...detailedLoanSizingData?.loanEconomics,
              ["interestRate"]: (option?.interestRate * 100).toFixed(3),
            },
          });
        }
      }
      formattedBuyDownOptions.push( {
        interestRate: `${(option?.interestRate * 100)?.toFixed(3)}%`,
        discountPoints: `${option?.discountPointPercentage?.toFixed(4)}`,
        discountPointsWithDollar: formatCurrency(
          option?.discountPointAmount,
          2
        ),
      });
    });
    formattedBuyDownOptions = sortInterestRates(formattedBuyDownOptions);
    setFormattedBuyDownOptionsState(formattedBuyDownOptions);
  }, [buyDownOptions, loanDetails]);

  const tableHeader = [
    {
      label: t("buyDownInterestRate"),
      headerClassName: "no-border-header border-right",
    },
    {
      label: t("discountPoints"),
      headerClassName: "no-border-header border-right",
    },
    {
      label: t("discountPointsWithDollar"),
      headerClassName: "no-border-header",
    },
  ];

  const handleInterestChange = (interestValue: any) => {
    const currentValue = buyDownOptions?.find(
      (option: any) =>
        option.interestRate ===
        Number(+interestValue.interestRate?.replace("%", "") / 100)
    );
    setCurrentSelectedInterestRate({ ...interestValue, totalBuyYield: currentValue.totalBuyYield });

    if (loanDetails?.sizingType === SizingTypeValue.QUICK_LOAN_SIZING) {
      setQuickLoanSizingData({
        ...quickLoanSizingData,
        loanEconomics: {
          ...quickLoanSizingData?.loanEconomics,
          ["interestRate"]: (currentValue?.interestRate * 100).toFixed(3),
        },
      });
    } else {
      setDetailedLoanSizingData({
        ...detailedLoanSizingData,
        loanEconomics: {
          ...detailedLoanSizingData?.loanEconomics,
          ["interestRate"]: (currentValue?.interestRate * 100).toFixed(3),
        },
      });
    }
    handleSaveApplication(
      loanDetails?.isApplicationSubmitted || isApplicationSubmitted,
      "",
      "",
      {...interestValue, totalBuyYield: currentValue.totalBuyYield}
    );
  };

  return (
    <div className="w-100" style={{ marginTop: "10px" }}>
      <BuyDownOptions
        header={t("buyDownOptions")}
        tableHeader={tableHeader}
        tableBody={formattedBuyDownOptionsState}
        handleInterestChange={handleInterestChange}
        currentSelectedInterestRate={currentSelectedInterestRate}
      />
    </div>
  );
}
