import { SizingTypeValue } from "../../types/enums";
import {
  calculateCurrentSelectedInterestRate,
  cleanedAdressString,
  sanitizePercentage,
} from "../../utils/Common";
import { prePayTypeToMonthsMap } from "../../utils/v2/common";
const getBridgeLoanType = (fetchData: any, propertyDetails: any) => {
  let returnStr = "BridgeLoan";
  if (
    propertyDetails?.[0]?.propertyInformation?.propertyType === "Multifamily" &&
    ["Stabilized", "Fix & Flip"].includes(fetchData?.loanType)
  ) {
    returnStr = "BridgeLoanMultifamily";
  } else if (fetchData?.loanType === "Ground Up") {
    returnStr = "BridgeLoanGroundUp";
  } else {
    returnStr = "BridgeLoan24";
  }
  return returnStr;
};

const sanitizeCurrency = (value: any, toFixed?: number) => {
  if (isNaN(Number(value))) {
    return value;
  }
  const data = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: toFixed || 2,
  }).format(value);

  return data;
};

const getSelectedBuyDownOptions = (
  loanData: any,
  loanResultData: any,
  currentSelectedInterestRate: any
) => {
  let buyDownOptions = loanData?.buyDownOptions;
  let selectedBuyDownOptions = findSelectedBuyDownOptions(buyDownOptions, currentSelectedInterestRate)
  if(selectedBuyDownOptions) {
    return { buyDownOptions, selectedBuyDownOptions }
  } else {
    buyDownOptions = loanResultData?.buyDownOptions;
    selectedBuyDownOptions = findSelectedBuyDownOptions( buyDownOptions, currentSelectedInterestRate)
    return { buyDownOptions, selectedBuyDownOptions }
  }
};

const findSelectedBuyDownOptions = (buyDownOptions: any, currentSelectedInterestRate: any) => {
  return buyDownOptions?.find((item: any) => {
    const itemInterestRate = item?.interestRate
      ? parseFloat(item.interestRate) * 100
      : null;
    const selectedInterestRate = currentSelectedInterestRate?.interestRate
      ? parseFloat(currentSelectedInterestRate?.interestRate.replace("%", ""))
      : null;

    if (itemInterestRate === null || selectedInterestRate === null) {
      return false;
    }
    return itemInterestRate?.toFixed(4) === selectedInterestRate?.toFixed(4);
  });
}

export function getTermSheetData (
  loanType: string,
  loanData: any,
  t: any,
  termSheetAction: string,
  termSheetData: any,
  defaultSelectedDataView: string,
  evaluationID: string,
  loanID: any,
  waiverRequest: any
) {
  const isViewOrDownload = ["view", "download", "view-from-list"].includes(
    termSheetAction
  );
  const isMinDataView = defaultSelectedDataView === "minimumDataView";

  const {
    loanDetails,
    fetchData,
    autoCalculatedLoanResults,
    assignedPartner,
    detailedLoanSizingData,
    quickLoanSizingData,
  } = loanData;

  let newDataToBeReffered: any = {
    ...loanDetails,
  };
  if (newDataToBeReffered?.sizingType === SizingTypeValue?.QUICK_LOAN_SIZING) {
    newDataToBeReffered = {
      ...newDataToBeReffered,
      ...quickLoanSizingData,
    };
  } else if (
    newDataToBeReffered?.sizingType === SizingTypeValue?.DETAILED_LOAN_SIZING
  ) {
    newDataToBeReffered = {
      ...newDataToBeReffered,
      ...detailedLoanSizingData,
    };
  }
  const propertyDetails = loanData?.propertyDetails?.length
    ? loanData?.propertyDetails
    : loanData?.loanDetails?.propertyDetails ??
      newDataToBeReffered.propertyDetails;
  const rulesResponse = Object.keys(loanData?.rulesResponse ?? {})?.length
    ? loanData?.rulesResponse
    : loanData?.loanDetails?.rulesResponse;
  const leverageAndPricingData = Object.keys(
    loanData?.leverageAndPricingData ?? {}
  )?.length
    ? loanData?.leverageAndPricingData
    : loanData?.loanDetails?.leverageAndPricingData;
  const loanId = loanID ? loanID : loanData?.loansApiResponse?.loanId || "";
  let loanResultData: any = {};
  if (assignedPartner) {
    loanResultData = loanData?.rulesResponse?.[loanDetails?.takeOutPartner]
      ?.loanResult?.[loanId]
      ? loanData?.rulesResponse?.[loanDetails?.takeOutPartner]?.loanResult?.[
          loanId
        ]
      : loanData?.rulesResponse?.tpResultMap?.assignedPartner
      ? loanData?.rulesResponse?.tpResultMap?.[assignedPartner]?.loanResult?.[
          loanId
        ]
      : loanDetails?.rulesResponse?.tpResultMap?.[assignedPartner]
          ?.loanResult?.[loanId];
  } else {
    loanResultData = loanData?.rulesResponse?.[loanDetails?.takeOutPartner]
      ?.loanResult?.[loanId]
      ? loanData?.rulesResponse?.[loanDetails?.takeOutPartner]?.loanResult?.[
          loanId
        ]
      : loanData?.rulesResponse?.tpResultMap
      ? loanData?.rulesResponse?.tpResultMap?.[
          Object.keys(loanDetails?.rulesResponse?.tpResultMap ?? {})?.[0]
        ]?.loanResult?.[loanId]
      : loanDetails?.rulesResponse?.tpResultMap?.[
          Object.keys(loanDetails?.rulesResponse?.tpResultMap ?? {})?.[0]
        ]?.loanResult?.[loanId] ?? newDataToBeReffered?.partnersResults?.loanResult?.[loanId];
  }

  const currentSelectedInterestRate =
    loanData?.currentSelectedInterestRate ??
    newDataToBeReffered?.currentSelectedInterestRate 

  const { buyDownOptions, selectedBuyDownOptions } = getSelectedBuyDownOptions(loanData, loanResultData, currentSelectedInterestRate)
    
  // const buyDownOptions =
  //   loanResultData?.buyDownOptions ?? loanData?.buyDownOptions;
  // const selectedBuyDownOptions = buyDownOptions?.find((item: any) => {
  //   const itemInterestRate = item?.interestRate
  //   ? parseFloat(item.interestRate) * 100
  //   : null;
  //   const selectedInterestRate = currentSelectedInterestRate?.interestRate
  //   ? parseFloat(currentSelectedInterestRate?.interestRate.replace("%", ""))
  //   : null;
    
  //   console.log("item", {item, itemInterestRate, selectedInterestRate, status: itemInterestRate?.toFixed(4) === selectedInterestRate?.toFixed(4) })
  //   if (itemInterestRate === null || selectedInterestRate === null) {
  //     return false;
  //   }
  //   return itemInterestRate?.toFixed(4) === selectedInterestRate?.toFixed(4);
  // });

  const maxAsIsLtv =
    // loanResultData?.maxLeverage?.maxAsIsLTV &&
    // !isNaN(+loanResultData?.maxLeverage?.maxAsIsLTV)
    //   ? `${+loanResultData?.maxLeverage?.maxAsIsLTV * 100} %`
    loanResultData?.toorakProceeds?.toorakAdjustedAsIsLtv &&
    !isNaN(+loanResultData?.toorakProceeds?.toorakAdjustedAsIsLtv)
      ? `${+loanResultData?.toorakProceeds?.toorakAdjustedAsIsLtv * 100} %`
      : !isNaN(+selectedBuyDownOptions?.toorakAdjustedAsIsLtv)
      ? `${+selectedBuyDownOptions?.toorakAdjustedAsIsLtv * 100} %`
      : "--";
  const monthlyQualifyingPayment =
    selectedBuyDownOptions?.monthlyQualifyingPayment ||
    loanResultData?.loanEconomics?.monthlyQualifyingPayment;
  const monthlyQualifyingPI =
    selectedBuyDownOptions?.monthlyQualifyingPI ||
    loanResultData?.loanEconomics?.monthlyQualifyingPI;
  const requestedLTVAsIs = loanResultData?.loanFeatures?.originalAsIsLtv;
  const requestedLTVARV =
    loanResultData?.loanFeatures?.originalAsIsRepairedLtv ||
    loanResultData?.loanFeatures?.arvLtvAsCompleted;
  const requestedLTC = loanResultData?.loanFeatures?.originalLtc;
  const totalBudget =
    newDataToBeReffered?.loanEconomics?.totalBudget ||
    loanResultData?.guidelineOutput?.totalBudget;
  const loanPurpose =
    newDataToBeReffered?.autoCalculatedLoanResults?.loanCharacterisation
      ?.loanPurpose ||
    loanResultData?.loanCharacterisation?.loanPurpose ||
    loanResultData?.loanCharacterization?.finalLoanCharacterization ||
    loanData?.rulesResponse?.tpResultMap?.[assignedPartner]?.loanResult?.[
      loanId
    ]?.loanCharacterization?.finalLoanCharacterization;
  const propertyAddress = cleanedAdressString(
    propertyDetails?.[0]?.propertyLocation ??
      newDataToBeReffered?.propertyLocation
  );
  const indexOfMostRestrictiveProperty =
    loanData?.autoCalculatedLoanResults?.loanCharacterisation
      ?.indexOfMostRestrictiveProperty ?? 0;
  const prepaymentPenaltyTypeMonths = newDataToBeReffered?.loanEconomics?.prepaymentPenaltyTypeMonths ?? 0
  const prepayPenaltyType = prepaymentPenaltyTypeMonths || prepaymentPenaltyTypeMonths === 0
    ? `${
      prepaymentPenaltyTypeMonths
      } Months - ${
        newDataToBeReffered?.loanEconomics?.prepayPenaltyType ??
        prePayTypeToMonthsMap?.[prepaymentPenaltyTypeMonths]
      }`
    : "--";

  const currentRateData = calculateCurrentSelectedInterestRate(
    {
      ...currentSelectedInterestRate,
      discountPoints: selectedBuyDownOptions?.discountPointPercentage != null  ? `${selectedBuyDownOptions?.discountPointPercentage?.toFixed(
        4
      )} %`: null,
      discountPointsWithDollar: selectedBuyDownOptions?.discountPointAmount != null  ? sanitizeCurrency(
        selectedBuyDownOptions?.discountPointAmount
      ) : null,
    },
    waiverRequest,
    buyDownOptions
  );
  const discountPoints = currentRateData?.discountPoints;
  const discountPointsWithDollar = currentRateData?.discountPointsWithDollar;

  const estimatedMinimumReserves =
    selectedBuyDownOptions?.minimumBorrowerLiquidity ||
    leverageAndPricingData?.requiredReserves?.minimumRequiredReserves;

  const noteRate = newDataToBeReffered?.loanEconomics?.originalInterestRate
    ? `${parseFloat(
        newDataToBeReffered?.loanEconomics?.originalInterestRate
      )?.toFixed(3)} %`
    : newDataToBeReffered?.loanEconomics?.dscrInterestRate
    ? `${parseFloat(
        newDataToBeReffered?.loanEconomics?.dscrInterestRate
      )?.toFixed(3)} %`
    : newDataToBeReffered?.currentSelectedInterestRate?.interestRate
    ? `${newDataToBeReffered?.currentSelectedInterestRate?.interestRate}`
    : newDataToBeReffered?.loanEconomics?.interestRate
    ? `${parseFloat(newDataToBeReffered?.loanEconomics?.interestRate)?.toFixed(
        3
      )} %`
    : "--";

  const loanTypeStr =
    loanType === "Investor DSCR"
      ? "Investor DSCR"
      : getBridgeLoanType(fetchData, propertyDetails);
  switch (loanTypeStr) {
    case "Investor DSCR":
      return [
        [
          {
            "Loan Id": evaluationID,
            "Property Address": propertyAddress ? propertyAddress : "--",
            "Requested Loan Amount":
              (isViewOrDownload
                ? sanitizeCurrency(
                    termSheetData?.termSheetLoanInfo?.loanAmount ||
                      termSheetData?.termSheetLoanInfo?.["Requested Loan Amount"]
                  )
                : sanitizeCurrency(
                    newDataToBeReffered?.loanEconomics?.borrowerRequested ||
                      newDataToBeReffered?.loanInformation?.borrowerRequested
                  )) || "--",
            "Max LTV": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.maxLTV ||
                termSheetData?.termSheetLoanInfo?.["Max LTV"]
              : maxAsIsLtv !== "--"
              ? sanitizePercentage(maxAsIsLtv, false, 2)
              : "--",
            DSCR:
              (isViewOrDownload &&
                (termSheetData?.termSheetLoanInfo?.dscr ||
                  termSheetData?.termSheetLoanInfo?.["DSCR"])) ||
              (isMinDataView &&
                (newDataToBeReffered?.loanEconomics?.dscr ?? null)) ||
              selectedBuyDownOptions?.dscr ||
              newDataToBeReffered?.leverageAndPricingData?.leverageCards
                ?.dscr ||
              leverageAndPricingData?.leverageCards?.dscr ||
              newDataToBeReffered?.loanOutputs?.dscr ||
              autoCalculatedLoanResults?.loanEconomics?.dscr ||
              "--",
            "Note Rate": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.interestRate ||
                termSheetData?.termSheetLoanInfo?.["Note Rate"]
              : currentRateData?.interestRate ?? noteRate,
            "Credit Score": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.fico ||
                termSheetData?.termSheetLoanInfo?.["Credit Score"]
              : newDataToBeReffered?.borrowerInformation?.creditScoreActual,
            "Property Type":
              (isViewOrDownload
                ? termSheetData?.termSheetLoanInfo?.propertyType ||
                  termSheetData?.termSheetLoanInfo?.["Property Type"]
                : propertyDetails?.[indexOfMostRestrictiveProperty]
                    ?.propertyInformation?.propertyType) ??
              newDataToBeReffered?.propertyInformation?.propertyType ??
              "--",
            "Property Usage":
              (isViewOrDownload
                ? termSheetData?.termSheetLoanInfo?.propertyUsage ||
                  termSheetData?.termSheetLoanInfo?.["Property Usage"]
                : propertyDetails?.[indexOfMostRestrictiveProperty]
                    ?.unitInformation?.[0]?.currentUnitUsage) ||
              "Investment Property",
            "Rental Status": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.rentalCharacterization ||
                termSheetData?.termSheetLoanInfo?.["Rental Status"]
              : `${
                  propertyDetails?.[indexOfMostRestrictiveProperty]
                    ?.unitInformation?.[0]?.rentalCharacterization ||
                  propertyDetails?.[0]?.propertyInformation
                    ?.rentalCharacterization ||
                  "--"
                }` || "--",
            "Estimated Minimum Reserves": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.minNoOfMonthsReserve ??
                    termSheetData?.termSheetLoanInfo?.[
                      "Estimated Minimum Reserves"
                    ]
                )
              : estimatedMinimumReserves
              ? sanitizeCurrency(estimatedMinimumReserves)
              : "--",
            "Estimated As-Is-Value": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.asIsValue ||
                    termSheetData?.termSheetLoanInfo?.["Estimated As-Is-Value"]
                )
              : propertyDetails?.[indexOfMostRestrictiveProperty]
                  ?.propertyEconomics?.asIsValue
              ? sanitizeCurrency(
                  propertyDetails?.[0]?.propertyEconomics?.asIsValue
                )
              : propertyDetails?.[0]?.propertyInformation?.asIsValue
              ? sanitizeCurrency(
                  propertyDetails?.[0]?.propertyInformation?.asIsValue
                )
              : newDataToBeReffered?.propertyInformation?.asIsValue
              ? sanitizeCurrency(
                  newDataToBeReffered?.propertyInformation?.asIsValue
                )
              : "--",
          },
        ],
        [
          {
            "Monthly Payment including Taxes and Insurance": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.monthlyQualifyingPayment ||
                termSheetData?.termSheetLoanInfo?.[
                  "Monthly Payment including Taxes and Insurance"
                ]
                ? `${sanitizeCurrency(
                    termSheetData?.termSheetLoanInfo
                      ?.monthlyQualifyingPayment ||
                      termSheetData?.termSheetLoanInfo?.[
                        "Monthly Payment including Taxes and Insurance"
                      ]
                  )}`
                : "--"
              : monthlyQualifyingPayment
              ? sanitizeCurrency(monthlyQualifyingPayment)
              : "--",
            "Monthly Principal and Interest or Interest Only": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.monthlyQualifyingPI ||
                termSheetData?.termSheetLoanInfo?.[
                  "Monthly Principal and Interest or Interest Only"
                ]
                ? `${sanitizeCurrency(
                    termSheetData?.termSheetLoanInfo?.monthlyQualifyingPI ||
                      termSheetData?.termSheetLoanInfo?.[
                        "Monthly Principal and Interest or Interest Only"
                      ]
                  )}`
                : "--"
              : monthlyQualifyingPI
              ? sanitizeCurrency(monthlyQualifyingPI)
              : "--",
            "Loan Term(Months)": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.loanTerm ||
                termSheetData?.termSheetLoanInfo?.["Loan Term(Months)"]
                ? `${
                    termSheetData?.termSheetLoanInfo?.loanTerm ||
                    termSheetData?.termSheetLoanInfo?.["Loan Term(Months)"]
                  }`
                : "360 Months"
              : newDataToBeReffered?.loanInformation?.loanTerm
              ? `${newDataToBeReffered?.loanInformation?.loanTerm} Months`
              : "360 Months",
            "Interest-only Period (If applicable)": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.ioPeriod ||
                termSheetData?.termSheetLoanInfo?.[
                  "Interest-only Period (If applicable)"
                ]
              : newDataToBeReffered?.loanEconomics?.ioPeriod
              ? `${newDataToBeReffered?.loanEconomics?.ioPeriod} Months`
              : "NA",
            "Lien Position": "First Lien",
            "Pre-Payment Penalty": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.prePaymentPenalty ||
                termSheetData?.termSheetLoanInfo?.["Pre-Payment Penalty"]
              : prepayPenaltyType || "--",
            "Product Type": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.loanType ||
                termSheetData?.termSheetLoanInfo?.["Product Type"]
              : loanTypeStr || "--",
            "Loan Purpose": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.loanPurpose ||
                termSheetData?.termSheetLoanInfo?.["Loan Purpose"]
              : `${
                  newDataToBeReffered?.loanInformation?.loanPurpose ||
                  loanPurpose ||
                  "--"
                }` || "--",
            "Citizenship Status": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.citizenshipStatus ||
                termSheetData?.termSheetLoanInfo?.["Citizenship Status"]
              : newDataToBeReffered?.borrowerInformation?.borrowerGuarantor
              ? newDataToBeReffered?.borrowerInformation?.borrowerGuarantor ===
                "Yes"
                ? newDataToBeReffered?.borrowerInformation?.immigrationStatus
                : "U.S. Citizen"
              : newDataToBeReffered?.borrowerInformation?.citizenshipStatus
              ? newDataToBeReffered?.borrowerInformation?.citizenshipStatus ===
                "No"
                ? "U.S. Citizen"
                : newDataToBeReffered?.borrowerInformation
                    ?.citizenshipStatus === "Yes"
                ? "Foreign National"
                : newDataToBeReffered?.borrowerInformation?.citizenshipStatus
              : "--",
            "Purchase Price": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.purchasePrice ||
                    termSheetData?.termSheetLoanInfo?.["Purchase Price"]
                )
              : propertyDetails?.[0]?.propertyEconomics
                  ?.propertyAcquisitionPrice
              ? sanitizeCurrency(
                  propertyDetails?.[0]?.propertyEconomics
                    ?.propertyAcquisitionPrice
                )
              : propertyDetails?.[0]?.propertyEconomics?.purchasePrice
              ? sanitizeCurrency(
                  propertyDetails?.[0]?.propertyEconomics?.purchasePrice
                )
              : newDataToBeReffered?.autoCalculatedPropResults?.[0]
                  ?.loanFeatures?.adjustedAsIsValue
              ? sanitizeCurrency(
                  newDataToBeReffered?.autoCalculatedPropResults?.[0]
                    ?.loanFeatures?.adjustedAsIsValue
                )
              : newDataToBeReffered?.loanInformation?.purchasePrice
              ? sanitizeCurrency(
                  newDataToBeReffered?.loanInformation?.purchasePrice
                )
              : "--",
            "Discount Points (%)": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.["Discount Points (%)"]
              : discountPoints
              ? `${discountPoints}`
              : "--",
            "Discount Points ($)": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.["Discount Points ($)"]
                )
              : discountPointsWithDollar
              ? `${sanitizeCurrency(discountPointsWithDollar)}`
              : "--",
          },
        ],
      ];

    case "BridgeLoan24":
      return [
        [
          {
            "Loan Id": evaluationID,
            "Property Address": propertyAddress ? propertyAddress : "--",
            "Loan Term(Months)": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.loanTerm ||
                termSheetData?.termSheetLoanInfo?.["Loan Term(Months)"]
              : loanDetails?.loanInformation?.loanTerm
              ? `${loanDetails?.loanInformation?.loanTerm} Months`
              : "12 Months",
            "Loan Purpose": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.loanPurpose ||
                termSheetData?.termSheetLoanInfo?.["Loan Purpose"]
              : loanDetails?.loanInformation?.loanPurpose
              ? `${loanDetails?.loanInformation?.loanPurpose}`
              : "--",
            "Property Type": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.propertyType ||
                termSheetData?.termSheetLoanInfo?.["Property Type"]
              : propertyDetails?.[indexOfMostRestrictiveProperty]
                  ?.propertyInformation?.propertyType ?? "--",
            "Guarantor's Experience": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.guarantorsExperience ||
                termSheetData?.termSheetLoanInfo?.["Guarantor's Experience"]
              : loanDetails?.borrowerInformation
                  ?.propertiesCompletedWithinLast36Months ?? "--",
            "Citizenship Status":
              loanDetails?.borrowerInformation?.borrowerGuarantor ||
              termSheetData?.termSheetLoanInfo?.["Citizenship Status"]
                ? loanDetails?.borrowerInformation?.borrowerGuarantor === "Yes"
                  ? loanDetails?.borrowerInformation?.immigrationStatus
                  : "U.S. Citizen"
                : "--",
            "Credit Score": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.creditScore ||
                termSheetData?.termSheetLoanInfo?.["Credit Score"]
              : loanDetails?.borrowerInformation?.originalCreditScore ?? "--",
          },
        ],
        [
          {
            "Estimated As-Is-Value": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.asIsValue ||
                    termSheetData?.termSheetLoanInfo?.["Estimated As-Is-Value"]
                )
              : propertyDetails?.[0]?.propertyEconomics?.orgAppraisalValue
              ? sanitizeCurrency(
                  propertyDetails?.[0]?.propertyEconomics?.orgAppraisalValue
                )
              : "--",
            "Product Type": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.baseProgram ||
                termSheetData?.termSheetLoanInfo?.["Product Type"]
              : loanDetails?.productType
              ? `${loanDetails?.productType} - ${loanDetails?.propertyType}`
              : `${fetchData?.productType} - ${fetchData?.propertyType}`,
            "Purchase Price": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.purchasePrice ||
                    termSheetData?.termSheetLoanInfo?.["Purchase Price"]
                )
              : propertyDetails?.[0]?.propertyEconomics?.purchasePrice
              ? sanitizeCurrency(
                  propertyDetails?.[0]?.propertyEconomics?.purchasePrice
                )
              : "--",
            "Total Rehab Budget": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.rehabCosts ||
                    termSheetData?.termSheetLoanInfo?.["Total Rehab Budget"]
                )
              : loanDetails?.loanEconomics?.totalRehabBudget
              ? sanitizeCurrency(loanDetails?.loanEconomics?.totalRehabBudget)
              : "--",
            "After Repair Value (ARV)": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.afterRepairValueARV ||
                    termSheetData?.termSheetLoanInfo?.[
                      "After Repair Value (ARV)"
                    ]
                )
              : propertyDetails?.[0]?.propertyEconomics?.asRepairedValueARV
              ? sanitizeCurrency(
                  propertyDetails?.[0]?.propertyEconomics?.asRepairedValueARV
                )
              : "--",
            "Requested LTV(As is)": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.requestedLTVAsIs ||
                    termSheetData?.termSheetLoanInfo?.["Requested LTV(As is)"]
                )
              : requestedLTVAsIs
              ? `${(requestedLTVAsIs * 100)?.toFixed(2)} %`
              : "--",
            "Requested LTV(ARV)": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.requestedLTVARV ||
                    termSheetData?.termSheetLoanInfo?.["Requested LTV(ARV)"]
                )
              : requestedLTVARV
              ? `${(requestedLTVARV * 100)?.toFixed(2)} %`
              : "--",
            "Requested LTC": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.requestedLTC ||
                    termSheetData?.termSheetLoanInfo?.["Requested LTC"]
                )
              : requestedLTC
              ? `${(requestedLTC * 100)?.toFixed(2)} %`
              : "--",
          },
        ],
      ];

    case "BridgeLoanGroundUp":
      return [
        [
          {
            "Loan Id": evaluationID,
            "Property Address": propertyAddress ? propertyAddress : "--",
            "Loan Term(Months)": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.loanTerm ||
                termSheetData?.termSheetLoanInfo?.["Loan Term(Months)"]
              : loanDetails?.loanInformation?.loanTerm
              ? `${loanDetails?.loanInformation?.loanTerm} Months`
              : "12 Months",
            "Loan Purpose": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.loanPurpose ||
                termSheetData?.termSheetLoanInfo?.["Loan Purpose"]
              : loanPurpose
              ? `${loanPurpose}`
              : "--",
            "Property Type": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.propertyType ||
                termSheetData?.termSheetLoanInfo?.["Property Type"]
              : propertyDetails[indexOfMostRestrictiveProperty]
                  ?.propertyInformation?.propertyType ?? "--",
            "Guarantor's Experience": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.guarantorsExperience ||
                termSheetData?.termSheetLoanInfo?.["Guarantor's Experience"]
              : loanDetails?.borrowerInformation?.borrowerGUCExperience ?? "--",
            "Citizenship Status": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.citizenshipStatus ||
                termSheetData?.termSheetLoanInfo?.["Citizenship Status"]
              : loanDetails?.borrowerInformation?.borrowerGuarantor
              ? loanDetails?.borrowerInformation?.borrowerGuarantor === "Yes"
                ? loanDetails?.borrowerInformation?.immigrationStatus
                : "U.S. Citizen"
              : "--",
            "Credit Score": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.creditScore ||
                termSheetData?.termSheetLoanInfo?.["Credit Score"]
              : loanDetails?.borrowerInformation?.originalCreditScore ?? "--",
          },
        ],
        [
          {
            "Estimated As-Is-Value": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.asIsValue ||
                    termSheetData?.termSheetLoanInfo?.["Estimated As-Is-Value"]
                )
              : propertyDetails[0]?.propertyEconomics?.asIsValue
              ? sanitizeCurrency(
                  propertyDetails[0]?.propertyEconomics?.asIsValue
                )
              : "--",
            "Product Type": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.baseProgram ||
                termSheetData?.termSheetLoanInfo?.["Product Type"]
              : loanDetails?.productType
              ? `${loanDetails?.productType} - ${loanDetails?.propertyType}`
              : `${fetchData?.productType} - ${fetchData?.propertyType}`,
            "Purchase Price": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.purchasePrice ||
                    termSheetData?.termSheetLoanInfo?.["Purchase Price"]
                )
              : propertyDetails[0]?.propertyEconomics?.purchasePrice
              ? sanitizeCurrency(
                  propertyDetails[0]?.propertyEconomics?.purchasePrice
                )
              : "--",
            "Total Rehab Budget": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.rehabCosts ||
                    termSheetData?.termSheetLoanInfo?.["Total Rehab Budget"]
                )
              : totalBudget && !isNaN(totalBudget)
              ? sanitizeCurrency(totalBudget)
              : "--",
            "After Repair Value (ARV)": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.afterRepairValueARV ||
                    termSheetData?.termSheetLoanInfo?.[
                      "After Repair Value (ARV)"
                    ]
                )
              : propertyDetails[0]?.propertyEconomics?.afterRepairValue
              ? sanitizeCurrency(
                  propertyDetails[0]?.propertyEconomics?.afterRepairValue
                )
              : "--",
            "Requested LTV(As is)": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.requestedLTVAsIs ||
                    termSheetData?.termSheetLoanInfo?.["Requested LTV(As is)"]
                )
              : requestedLTVAsIs
              ? `${(requestedLTVAsIs * 100)?.toFixed(2)} %`
              : "--",
            "Requested LTV(ARV)": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.requestedLTVARV ||
                    termSheetData?.termSheetLoanInfo?.["Requested LTV(ARV)"]
                )
              : requestedLTVARV
              ? `${(requestedLTVARV * 100)?.toFixed(2)} %`
              : "--",
            "Requested LTC": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.requestedLTC ||
                    termSheetData?.termSheetLoanInfo?.["Requested LTC"]
                )
              : requestedLTC
              ? `${(requestedLTC * 100)?.toFixed(2)} %`
              : "--",
          },
        ],
      ];

    case "BridgeLoanMultifamily":
      return [
        [
          {
            "Loan Id": evaluationID,
            "Property Address": propertyAddress ? propertyAddress : "--",
            "Loan Term(Months)": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.loanTerm ||
                termSheetData?.termSheetLoanInfo?.["Loan Term(Months)"]
              : loanDetails?.loanInformation?.loanTerm
              ? `${loanDetails?.loanInformation?.loanTerm} Months`
              : "12 Months",
            "Loan Purpose": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.loanPurpose ||
                termSheetData?.termSheetLoanInfo?.["Loan Purpose"]
              : loanDetails?.loanInformation?.loanPurpose
              ? `${loanDetails?.loanInformation?.loanPurpose}`
              : "--",
            "Property Type": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.propertyType ||
                termSheetData?.termSheetLoanInfo?.["Property Type"]
              : propertyDetails[indexOfMostRestrictiveProperty]
                  ?.propertyInformation?.propertyType ?? "--",
            "Guarantor's Experience": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.guarantorsExperience ||
                termSheetData?.termSheetLoanInfo?.["Guarantor's Experience"]
              : loanDetails?.borrowerInformation
                  ?.propertiesCompletedWithinLast36Months ?? "--",
            "Citizenship Status": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.citizenshipStatus ||
                termSheetData?.termSheetLoanInfo?.["Citizenship Status"]
              : loanDetails?.borrowerInformation?.borrowerGuarantor
              ? loanDetails?.borrowerInformation?.borrowerGuarantor === "Yes"
                ? loanDetails?.borrowerInformation?.immigrationStatus
                : "U.S. Citizen"
              : "--",
            "Credit Score": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.creditScore ||
                termSheetData?.termSheetLoanInfo?.["Credit Score"]
              : loanDetails?.borrowerInformation?.originalCreditScore ?? "--",
          },
        ],
        [
          {
            "Estimated As-Is-Value": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.asIsValue ||
                    termSheetData?.termSheetLoanInfo?.["Estimated As-Is-Value"]
                )
              : propertyDetails[0]?.propertyEconomics?.orgAppraisalValue
              ? sanitizeCurrency(
                  propertyDetails[0]?.propertyEconomics?.orgAppraisalValue
                )
              : "--",
            "Product Type": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.baseProgram ||
                termSheetData?.termSheetLoanInfo?.["Product Type"]
              : loanDetails?.productType
              ? `${loanDetails?.productType} - ${loanDetails?.propertyType}`
              : `${fetchData?.productType} - ${fetchData?.propertyType}`,
            "Purchase Price": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.purchasePrice ||
                    termSheetData?.termSheetLoanInfo?.["Purchase Price"]
                )
              : propertyDetails[0]?.propertyEconomics?.purchasePrice
              ? sanitizeCurrency(
                  propertyDetails[0]?.propertyEconomics?.purchasePrice
                )
              : "--",
            "Total Rehab Budget": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.rehabCosts ||
                    termSheetData?.termSheetLoanInfo?.["Total Rehab Budget"]
                )
              : loanDetails?.loanEconomics?.totalRehabBudget
              ? sanitizeCurrency(loanDetails?.loanEconomics?.totalRehabBudget)
              : "--",
            "After Repair Value (ARV)": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.afterRepairValueARV ||
                    termSheetData?.termSheetLoanInfo?.[
                      "After Repair Value (ARV)"
                    ]
                )
              : propertyDetails[0]?.propertyEconomics?.asRepairedValueARV
              ? sanitizeCurrency(
                  propertyDetails[0]?.propertyEconomics?.asRepairedValueARV
                )
              : "--",
            "Requested LTV(As is)": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.requestedLTVAsIs ||
                    termSheetData?.termSheetLoanInfo?.["Requested LTV(As is)"]
                )
              : requestedLTVAsIs
              ? `${(requestedLTVAsIs * 100)?.toFixed(2)} %`
              : "--",
            "Requested LTV(ARV)": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.requestedLTVARV ||
                    termSheetData?.termSheetLoanInfo?.["Requested LTV(ARV)"]
                )
              : requestedLTVARV
              ? `${(requestedLTVARV * 100)?.toFixed(2)} %`
              : "--",
            "Requested LTC": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.requestedLTC ||
                    termSheetData?.termSheetLoanInfo?.["Requested LTC"]
                )
              : requestedLTC
              ? `${(requestedLTC * 100)?.toFixed(2)} %`
              : "--",
          },
        ],
      ];

    default:
      break;
  }
};

export const getSuggestedLoanTerms = (
  loanType: string,
  loanData: any,
  t: any,
  termSheetAction: string,
  termSheetData?: any
) => {
  const isViewOrDownload = ["view", "download"].includes(termSheetAction);
  const { loanDetails, propertyDetails, autoCalculatedLoanResults, fetchData } =
    loanData;
  const leverageAndPricingData =
    loanData?.leverageAndPricingData ||
    loanData?.loanDetails?.leverageAndPricingData;
  const tableBody = leverageAndPricingData?.proceeds?.tableBody;

  const tempMaxLoanAmount = tableBody?.[0]?.data?.slice(-1)[0];
  let tempAmountRequested = tableBody?.[2]?.data?.slice(-1)[0];

  if (tempAmountRequested && typeof tempAmountRequested === "string") {
    tempAmountRequested = tempAmountRequested.replace(/-/g, "");
  }
  const maxLoanAmount: any =
    !isNaN(Number(tempMaxLoanAmount)) &&
    typeof Number(tempMaxLoanAmount) === "number"
      ? sanitizeCurrency(tempMaxLoanAmount)
      : tempMaxLoanAmount;
  const amountRequested: any =
    !isNaN(Number(tempAmountRequested)) &&
    typeof Number(tempAmountRequested) === "number"
      ? sanitizeCurrency(tempAmountRequested)
      : tempAmountRequested;

  const loanTypeStr =
    loanType === "Investor DSCR"
      ? "Investor DSCR"
      : getBridgeLoanType(fetchData, propertyDetails);
  switch (loanTypeStr) {
    case "BridgeLoan24":
      return [
        [
          {
            "Initial Loan Amount": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.loanAmount ||
                    termSheetData?.termSheetLoanInfo?.["Initial Loan Amount"]
                )
              : sanitizeCurrency(
                  loanDetails?.loanEconomics?.initialLoanAmount
                ) || "--",
            "Financed Budget": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.rehabBudgetLenderFunded ||
                    termSheetData?.termSheetLoanInfo?.["Financed Budget"]
                )
              : loanDetails?.loanEconomics?.financedBudget
              ? sanitizeCurrency(loanDetails?.loanEconomics?.financedBudget)
              : "--",
            "Requested Loan Amount": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.amountRequested ||
                    termSheetData?.termSheetLoanInfo?.["Requested Loan Amount"]
                )
              : leverageAndPricingData?.proceeds
              ? amountRequested
              : "--",
          },
        ],
        [
          {
            "Note Rate": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.interestRate ||
                termSheetData?.termSheetLoanInfo?.["Note Rate"]
              : loanDetails?.loanEconomics?.originalInterestRate ||
                loanDetails?.loanEconomics?.interestRate
              ? `${
                  loanDetails?.loanEconomics?.originalInterestRate ||
                  loanDetails?.loanEconomics?.interestRate
                } %`
              : "--",
            "Interest Type": "Fixed",
            "--": "--",
          },
        ],
      ];
    case "BridgeLoanGroundUp":
      return [
        [
          {
            "Initial Loan Amount": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.loanAmount ||
                termSheetData?.termSheetLoanInfo?.["Initial Loan Amount"]
              : sanitizeCurrency(
                  loanDetails?.loanEconomics?.initialLoanAmount
                ) || "--",
            "Financed Budget": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.rehabBudgetLenderFunded ||
                    termSheetData?.termSheetLoanInfo?.["Financed Budget"]
                )
              : loanDetails?.loanEconomics?.financedBudget
              ? sanitizeCurrency(loanDetails?.loanEconomics?.financedBudget)
              : "--",
            "Requested Loan Amount": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.amountRequested ||
                    termSheetData?.termSheetLoanInfo?.["Requested Loan Amount"]
                )
              : leverageAndPricingData?.proceeds
              ? amountRequested
              : "--",
          },
        ],
        [
          {
            "Note Rate": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.interestRate ||
                termSheetData?.termSheetLoanInfo?.["Note Rate"]
              : loanDetails?.loanEconomics?.interestRate
              ? `${loanDetails?.loanEconomics?.interestRate} %`
              : "--",
            "Interest Type": "Fixed",
            "--": "--",
          },
        ],
      ];
    case "BridgeLoanMultifamily":
      return [
        [
          {
            "Initial Loan Amount": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.loanAmount ||
                    termSheetData?.termSheetLoanInfo?.["Initial Loan Amount"]
                )
              : sanitizeCurrency(
                  loanDetails?.loanEconomics?.initialLoanAmount
                ) || "--",
            "Financed Budget": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.rehabBudgetLenderFunded ||
                    termSheetData?.termSheetLoanInfo?.["Financed Budget"]
                )
              : loanDetails?.loanEconomics?.financedBudget
              ? sanitizeCurrency(loanDetails?.loanEconomics?.financedBudget)
              : "--",
            "Requested Loan Amount": isViewOrDownload
              ? sanitizeCurrency(
                  termSheetData?.termSheetLoanInfo?.amountRequested ||
                    termSheetData?.termSheetLoanInfo?.["Requested Loan Amount"]
                )
              : leverageAndPricingData?.proceeds
              ? amountRequested
              : "--",
          },
        ],
        [
          {
            "Note Rate": isViewOrDownload
              ? termSheetData?.termSheetLoanInfo?.interestRate ||
                termSheetData?.termSheetLoanInfo?.["Note Rate"]
              : loanDetails?.loanEconomics?.interestRate
              ? `${loanDetails?.loanEconomics?.interestRate} %`
              : "--",
            "Interest Type": "Fixed",
            "--": "--",
          },
        ],
      ];
    default:
      break;
  }
};

export const formatDateForPDFName = (date: any) => {
  const pad = (num: any) => {
    return String(num).padStart(2, "0");
  }; // Helper function to pad single digits
  const month = pad(date.getMonth() + 1); // Months are zero-based
  const day = pad(date.getDate());
  const year = date.getFullYear();
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${month}-${day}-${year}_${hours}-${minutes}-${seconds}`;
};

export function isNotEmptyObject(obj: any) {
  return (
    obj &&
    typeof obj === "object" &&
    !Array.isArray(obj) &&
    Object.keys(obj ?? {}).length > 0
  );
}
