import { useState, useContext, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import { Box, Stack } from "@mui/system";
import { useTheme } from "@mui/material/styles";

import Header from "../../../components/Header";
import BridgeLoanResultTab from "../../../components/common/Loans/BridgeLoanResultTab";
import CustomChip from "../../../components/common/CustomChip";
import Disclaimer from "../../../components/common/Disclaimer";

import { RootContext } from "../../../context/RootContext";
import {
  intialDetailedLoanSizingState,
  LoansContext,
  unitInformation,
} from "../../../context/LoansContext";

import ShareIcon from "../../../assets/ShareIcon.svg";
import ExpandButton from "../../../assets/ExpandButton.svg";
import ShrinkButton from "../../../assets/ShrinkButton.svg";
import CheckedCircle from "../../../assets/CheckedCircle.svg";
import InfoErrorIcon from "../../../assets/InfoErrorIcon.svg";
import InfoMsgIcon from "../../../assets/InfoMsgIcon.svg";
import AlertTriangle from "../../../assets/AlertTriangle.svg";
import WarnigIconYellow from "../../../assets/WarningIconYellow.svg";
import CloseIcon from "../../../assets/Close-Icon-1.svg";

import {
  convertLoanSizingData,
  fetchRequestWaiver,
  loanDataBasedOnLoanId,
  patchSaveApplication,
  postCalculateEligibility,
  postCreateLoan,
  postUpdateLoanUrl,
  postUpdatePropertyUrl,
} from "../../../services/network";
import {
  getTestTabResultData,
  handleCondoEligibilityModalChange,
  renderFieldUI,
  sampleCondoEligibility,
} from "../v1/Common";
import PartnerSelectionView from "../v1/PartnerAndProgrammeSelectionWrapper";
import Banners from "../v2/Banners/Banners";

import LoanFieldsUIWrapper from "./LoanFieldsUIWrapper";
import { SizingType, SizingTypeValue } from "../../../types/enums";
import {
  calculateCurrentSelectedInterestRate,
  exportHTML,
  getCookie,
  getFormattedDate,
  getPercentageValue,
  getTableValue,
  getValue,
  normalizeResultLoanData,
  normalizeSubmitApplicationLoanData,
  normalizeSubmitApplicationPropertyData,
  sanitizeData,
} from "../../../utils/Common";
import { ObjectType } from "../../../types/tableTypes";
import {
  Button,
  Divider,
  Fade,
  Grid,
  Snackbar,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { getCondoEligibilityFields, TabLists } from "../v1/LoanFields";
import { TabPanelProps } from "../../../types/fieldTypes";
import CustomCard from "../../../components/common/BasicCustomCard";
import LeverageTable from "../../../components/LeverageTable";
import moment from "moment";
import { getLeveragePricingTestsConfig } from "../v1/LeveragePricing";
import BuyDownOptionsWrapper from "./OutputFieldsUI/components/BuyDownOptions/BuyDownOptionsWrapper";
import {
  convertQuickToDetailedLoanData,
  fetchGuidelinesAndBuyDownOptions,
  formatCurrency,
  prePayTypeToMonthsMap,
} from "../../../utils/v2/common";
import RequestWaiverWrapper from "./OutputFieldsUI/components/RequestWaivers/RequestWaiverWrapper";
import { LoadingButton } from "@mui/lab";
import CustomModal from "../../../components/common/CustomModal";
import { errorSnackBar, snackbarStyle } from "../v1/style";
import { getTermSheetVersions } from "../../../services/termSheet";
import { finalPricingTables } from "../../../utils/mappings";

type ParamType = {
  loanType: string | undefined;
  loanId: string | undefined;
  propType: string | undefined;
};

export default function LoansPageV2() {
  const { t } = useTranslation();
  const params = useParams<ParamType>();
  const theme = useTheme();

  const RootContextData = useContext(RootContext);
  const loanContextData = useContext(LoansContext);

  const { loanType, loanId, propType } = params;
  const {
    decodedTokenData,
    userPermissions,
    setDynamicLoader,
    dynamicConfig,
    templateLabels,
    setTemplateLabels,
    DSCROrgPartnerMapping,
    setTermSheetVersions,
    showTermSheet,
    setCurrentPath,
    showKeyDealMetrics,
    setShowKeyDealMetrics,
    originatorAccountMappingData,
    setTermSheetAction,
  } = RootContextData;
  const {
    chipsData,
    loanDetails,
    loansApiResponse,
    isApplicationSubmitted,
    expandLeverageAndTests,
    leverageTestsConfig,
    leverageAndPricingData,
    testTabData,
    autoCalculatedLoanResults,
    autoCalculatedPropResults,
    condoEligibilityConfig,
    condoEligibility,
    condoEligibilityFormData,
    failedFinalRate,
    fetchData,
    ratesAsOfDate,
    newDebouncedValue,
    noAssignedPartner,
    assignedPartner,
    expandAvailableProgramsView,
    rulesResponse,
    templateResponseData,
    eligiblePartnerResults,
    partnersResults,
    quickLoanSizingData,
    detailedLoanSizingData,
    // setState functions
    setChipsData,
    setSizingType,
    setAutoCalculatedFields,
    setLoanDetails,
    setIsApplicationSubmitted,
    setShowSnackBar,
    setExpandLeverageAndTests,
    setLeverageTestsConfig,
    setLeverageAndPricingData,
    setUnitInfoTemplate,
    setTestTabData,
    setAutoCalculatedLoanResults,
    setAutoCalculatedPropResults,
    setLoansApiResponse,
    setCondoEligibilityConfig,
    setCondoEligibility,
    setCondoEligibilityFormData,
    setAssignedPartner,
    setExpandAvailableProgramsView,
    setRulesResponse,
    setTemplateResponseData,
    setEligiblePartnerResults,
    setPartnersResults,
    setQuickLoanSizingData,
    setDetailedLoanSizingData,
    fieldConfig,
    detailedLoanSizingErrors,
    setDetailedLoanSizingErrors,
    setBuyDownOptions,
    buyDownOptions,
    currentSelectedInterestRate,
    setCurrentSelectedInterestRate,
    latestTermSheetVersion,
    selectedSizingType,
    showSnackBar,
    setPriceExceptionStatus,
  } = loanContextData || {};

  const [applicationName, setApplicationName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openSaveApplicationModal, setOpenSaveApplicationModal] =
    useState<boolean>(false);
  const [evaluationID, setEvaluationID] = useState<number>();
  const [openWarningBeforeSubmit, setOpenWarningBeforeSubmit] =
    useState<boolean>(false);
  const [disableSubmitApplication, setDisableSubmitApplication] =
    useState<boolean>(true);
  const [applicationError, setApplicationError] = useState<string>("");
  const [showLoanDetails, setShowLoanDetails] = useState<boolean>(true);
  const [showBanners, setShowBanners] = useState<boolean>(true);
  const [tcLoanId, setTcLoanId] = useState<string>("");
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isExpanded, setIsExpanded] = useState<ObjectType>({});
  const [snackBarMsg, setSnackBarMsg] = useState<string>("");
  const [showSendBtn, setShowSendBtn] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [showWarningForWaiversSubmit, setShowWarningForWaiversSubmit] =
    useState<boolean>(false);
  const assignedOrTakeOutPartnerId =
    assignedPartner ?? (loanDetails?.takeOutPartner || "");

  const orgFundingType =
    loanDetails?.fundingType || getCookie("orgFundingType");

  const realmHeaders = {
    "kc-realm": dynamicConfig?.ViteAppKcRealm,
  };

  const disclaimer = useMemo(() => {
    const showDisclaimer = dynamicConfig?.disclaimer !== "";
    if (!showDisclaimer) return "";
    return (
      <>
        {dynamicConfig?.disclaimer ?? (
          <span>
            {t("loanDisclaimerText")}{" "}
            <a href={dynamicConfig?.ViteAppTcRedirect} target="_blank">
              {t("toorakConnect")}
            </a>
          </span>
        )}
        {dynamicConfig?.rateSheetLink && (
          <IconButton
            onClick={() => window.open(dynamicConfig?.rateSheetLink, "_blank")}
            data-testid="rate-sheet-icon"
          >
            <img src={ShareIcon} alt="share-icon" />
          </IconButton>
        )}
      </>
    );
  }, [dynamicConfig]);

  function validateForm() {
    let isError: boolean = false;
    let tempFormErrors: ObjectType = { ...(detailedLoanSizingErrors ?? {}) };
    const propErrors = [...(tempFormErrors?.propertyDetails ?? [])];
    const loanErrors: any = {
      borrowerInformation: tempFormErrors?.borrowerInformation,
      loanInformation: tempFormErrors?.loanInformation,
      loanEconomics: tempFormErrors?.loanEconomics,
    };
    detailedLoanSizingData?.propertyDetails?.forEach(
      (property: any, index: number) => {
        Object.keys(property).forEach((key: string) => {
          const unitErrors = [
            ...((tempFormErrors?.propertyDetails ?? [])?.[index]?.[
              "unitInformation"
            ] || []),
          ];
          const currentConfig = fieldConfig?.propertyDetails?.[key];
          currentConfig?.fields?.forEach((config: any) => {
            if (
              config?.required &&
              config?.isVisible(property) &&
              !property[key]?.[config.id] &&
              key !== "unitInformation"
            ) {
              isError = true;
              propErrors[index] = {
                ...propErrors[index],
                [key]: {
                  ...propErrors[index]?.[key],
                  [config.id]: "thisFieldIsRequired",
                },
              };
            } else if (
              config?.required &&
              config?.isVisible(property) &&
              key === "unitInformation"
            ) {
              property[key]?.forEach((unit: any, unitIndex: number) => {
                if (!unit[config.id]) {
                  unitErrors[unitIndex] = {
                    ...unitErrors[unitIndex],
                    [config.id]: "thisFieldIsRequired",
                  };
                }
              });
            }
          });
          propErrors[index] = {
            ...propErrors[index],
            unitInformation: unitErrors,
          };
        });
      }
    );
    fieldConfig?.borrowerDetails?.borrowerInformation?.fields?.forEach(
      (bConfig: any) => {
        if (
          bConfig?.required &&
          bConfig?.isVisible(detailedLoanSizingData?.borrowerInformation) &&
          !detailedLoanSizingData?.borrowerInformation?.[bConfig.id]
        ) {
          isError = true;
          loanErrors.borrowerInformation = {
            ...loanErrors.borrowerInformation,
            [bConfig.id]: "thisFieldIsRequired",
          };
        }
      }
    );
    Object.keys(fieldConfig?.loanDetails).forEach((key: string) => {
      fieldConfig?.loanDetails?.[key]?.fields?.forEach((lConfig: any) => {
        if (
          lConfig?.required &&
          lConfig?.isVisible(detailedLoanSizingData?.[key]) &&
          (detailedLoanSizingData?.[key]?.[lConfig.id] === null ||
            detailedLoanSizingData?.[key]?.[lConfig.id] === undefined ||
            detailedLoanSizingData?.[key]?.[lConfig.id] === "")
        ) {
          isError = true;
          loanErrors[key] = {
            ...loanErrors[key],
            [lConfig.id]: "thisFieldIsRequired",
          };
        }
      });
    });
    fieldConfig?.borrowerDetails?.borrowerInformation?.fields?.forEach(
      (bConfig: any) => {
        if (
          bConfig?.required &&
          bConfig?.isVisible(detailedLoanSizingData?.borrowerInformation) &&
          !detailedLoanSizingData?.borrowerInformation?.[bConfig.id]
        ) {
          isError = true;
          loanErrors.borrowerInformation = {
            ...loanErrors.borrowerInformation,
            [bConfig.id]: "thisFieldIsRequired",
          };
        }
      }
    );
    setDetailedLoanSizingErrors({
      ...loanErrors,
      propertyDetails: propErrors,
    });
    return isError;
  }

  async function handleConvertion() {
    try {
      const reqBody = {
        loanId,
        sizingType: SizingTypeValue.DETAILED_LOAN_SIZING,
      };

      await convertLoanSizingData(reqBody);
      const convertedData = convertQuickToDetailedLoanData(quickLoanSizingData);
      const tempData: ObjectType = {
        orgId:
          loanDetails?.orgId || getCookie("orgId") || dynamicConfig?.clientId,
        fundingType: orgFundingType === "undefined" ? "normal" : orgFundingType,
        applicationName: applicationName || evaluationID?.toString(),
        evaluationID,
        isApplicationSubmitted: loanDetails?.isApplicationSubmitted || false,
        testTabData,
        ratesAsOf: ratesAsOfDate,
        leverageAndPricingData,
        // autoCalculatedLoanResults,
        // autoCalculatedPropResults: [...autoCalculatedPropResults],
        tcLoanId: loanDetails?.tcLoanId,
        takeOutPartner: assignedPartner,
        rulesResponse,
        eligiblePartnerResults,
        partnersResults,
        ...convertedData,
      };

      const res = await patchSaveApplication(loanId, tempData);
      setDetailedLoanSizingData({
        ...convertedData,
      });
      setLoanDetails({
        ...loanDetails,
        sizingType: SizingTypeValue?.DETAILED_LOAN_SIZING,
        detailedLoanSizingData: convertedData,
      });
      setSizingType(SizingType.DETAILED_LOAN_SIZING);
      await autoGenerateTermSheet();
    } catch (err) {
      console.error(
        "Something went wrong while converting the loan. ERROR:",
        err
      );
    }
  }

  const autoGenerateTermSheet = async () => {
    setTermSheetAction("autogenerate");
  };

  async function handleSaveApplication(
    isApplicationSubmit?: boolean,
    tempTcLoanId?: string,
    additionalStr?: string,
    currentInterestRate?: any,
    skipSubmitCheck?: boolean
  ) {
    const isSubmittedApp = isApplicationSubmit || false;
    const tempLoanId = tempTcLoanId || tcLoanId || "";
    if (!applicationName && !isSubmittedApp && !skipSubmitCheck) {
      setApplicationError(t("thisFieldIsRequired"));
      return;
    }
    setIsLoading(true);
    try {
      let loanData: any = {};
      if (loanDetails?.sizingType === SizingTypeValue?.QUICK_LOAN_SIZING) {
        loanData = {
          borrowerInformation: quickLoanSizingData?.borrowerInformation || {},
          loanInformation: {
            loanPurpose: quickLoanSizingData?.loanInformation?.loanPurpose,
          },
          loanEconomics: {
            ...quickLoanSizingData?.loanEconomics,
            borrowerRequested:
              quickLoanSizingData?.loanInformation?.borrowerRequested,
            purchasePrice: quickLoanSizingData?.loanInformation?.purchasePrice,
          },
          loanOutputs: quickLoanSizingData?.loanOutputs || {},
          propertyDetails: [
            {
              propertyLocation: quickLoanSizingData?.propertyLocation || {},
              propertyInformation:
                quickLoanSizingData?.propertyInformation || {},
              propertyEconomics: quickLoanSizingData?.propertyEconomics || {},
            },
          ],
        };
      }
      if (loanDetails?.sizingType === SizingTypeValue?.DETAILED_LOAN_SIZING) {
        loanData = { ...detailedLoanSizingData };
      }
      const currentRateData: any =
        currentInterestRate ?? currentSelectedInterestRate;
      if (currentInterestRate) {
        loanData.loanEconomics.interestRate = Number(
          currentInterestRate?.interestRate?.replace("%", "")
        );
      }
      loanData.loanEconomics.originationPoints =
        leverageAndPricingData?.leverageCards?.originationPoints;
      const tempData: ObjectType = {
        orgId:
          loanDetails?.orgId || getCookie("orgId") || dynamicConfig?.clientId,
        fundingType: orgFundingType === "undefined" ? "normal" : orgFundingType,
        applicationName: applicationName || evaluationID?.toString(),
        evaluationID,
        isApplicationSubmitted:
          loanDetails?.isApplicationSubmitted || isSubmittedApp,
        testTabData,
        ratesAsOf: ratesAsOfDate,
        leverageAndPricingData,
        autoCalculatedLoanResults,
        autoCalculatedPropResults: [...(autoCalculatedPropResults ?? [])],
        tcLoanId: tempLoanId,
        takeOutPartner: assignedPartner,
        rulesResponse,
        eligiblePartnerResults,
        partnersResults,
        buyDownOptions,
        currentSelectedInterestRate: currentRateData,
        ...loanData,
      };

      const res = await patchSaveApplication(loanId, tempData);
      setOpenSaveApplicationModal(false);
      setShowLoanDetails(true);
      if (res?.data) {
        if (!isSubmittedApp && !loanDetails?.isApplicationSubmitted) {
          const str = additionalStr
            ? `${t(additionalStr)} and ${t("changesSaved")}`
            : t("changesSaved");
          setSnackBarMsg(str);
          setShowSnackBar(true);
        } else if (loanDetails?.isApplicationSubmitted) {
          const str = `${t("changesSaved")}\n \n ${t(
            "toastAfterSaveForSubmitted"
          )}`;
          setSnackBarMsg(str);
          setShowSnackBar(true);
        }
        const body = [loanId];
        const { data } = await loanDataBasedOnLoanId(body);
        setLoansApiResponse(data?.data["0"]);
        setLoanDetails(data?.data?.["0"]?.data);
        setIsApplicationSubmitted(
          data?.data?.["0"]?.data?.isApplicationSubmitted || isSubmittedApp
        ); 
        setApplicationName(applicationName || (evaluationID?.toString() ?? ""))
      } else {
        setShowSnackBar(false);
        setSnackBarMsg("changesFailedSave");
        setIsApplicationSubmitted(
          loanDetails?.isApplicationSubmitted || isSubmittedApp
        );
      }
    } catch (err) {
      console.error(err);
      setShowSnackBar(false);
      setSnackBarMsg("changesFailedSave");
    } finally {
      setIsLoading(false);
    }
  }

  const getLoanDataBasedOnLoanId = async () => {
    setDynamicLoader(true);
    try {
      // const url = getURL("online-sizer/fetch");
      const body = [loanId];
      const { data } = await loanDataBasedOnLoanId(body);
      const keysToBeChecked = [
        "loanInformation",
        "loanEconomics",
        "propertyDetails",
        "borrowerInformation",
      ];

      if (
        data?.data?.["0"]?.data?.sizingType ===
        SizingTypeValue?.QUICK_LOAN_SIZING
      ) {
        let reqBody: any = {};
        // const keys = ["borrowerInformation", "loanInformation", "loanEconomics", "propertyDetails"];
        if (
          !keysToBeChecked.some((key) =>
            data?.data?.["0"]?.data?.hasOwnProperty(key)
          )
        ) {
          reqBody = {
            loanInformation: intialDetailedLoanSizingState.loanInformation,
            propertyInformation: {
              propertyType:
                data?.data?.["0"]?.data?.propertyType ===
                "Single Family/Detached"
                  ? "SFR"
                  : data?.data?.["0"]?.data?.propertyType,
            },
          };
        } else {
          reqBody = {
            borrowerInformation: data?.data?.["0"]?.data?.borrowerInformation,
            loanInformation: {
              ...data?.data?.["0"]?.data?.loanInformation,
              borrowerRequested:
                data?.data?.["0"]?.data?.loanEconomics?.borrowerRequested,
              purchasePrice:
                data?.data?.["0"]?.data?.loanEconomics?.purchasePrice,
            },
            loanEconomics: data?.data?.["0"]?.data?.loanEconomics,
            propertyLocation:
              data?.data?.["0"]?.data?.propertyDetails?.[0]?.propertyLocation,
            propertyInformation:
              data?.data?.["0"]?.data?.propertyDetails?.[0]
                ?.propertyInformation,
            propertyEconomics:
              data?.data?.["0"]?.data?.propertyDetails?.[0]?.propertyEconomics,
          };
        }
        setQuickLoanSizingData(reqBody ?? {});
      } else if (
        data?.data?.["0"]?.data?.sizingType ===
        SizingTypeValue?.DETAILED_LOAN_SIZING
      ) {
        let reqBody: any = {};
        if (
          !keysToBeChecked.some((key) =>
            data?.data?.["0"]?.data?.hasOwnProperty(key)
          )
        ) {
          reqBody = {
            loanInformation: intialDetailedLoanSizingState.loanInformation,
            loanEconomics: intialDetailedLoanSizingState.loanEconomics,
            propertyDetails: [
              {
                propertyInformation: {
                  propertyType:
                    data?.data?.["0"]?.data?.propertyType ===
                    "Single Family/Detached"
                      ? "SFR"
                      : data?.data?.["0"]?.data?.propertyType,
                },
                propertyEconomics: {
                  decliningMarkets: "No",
                },
                unitInformation: [{}],
              },
            ],
          };
        } else {
          reqBody = {
            borrowerInformation: data?.data?.["0"]?.data?.borrowerInformation,
            loanInformation: data?.data?.["0"]?.data?.loanInformation,
            loanEconomics: data?.data?.["0"]?.data?.loanEconomics,
            propertyDetails: data?.data?.["0"]?.data?.propertyDetails?.map(
              (property: any) => {
                return {
                  propertyLocation: property?.propertyLocation ?? {},
                  propertyInformation: property?.propertyInformation ?? {},
                  propertyEconomics: property?.propertyEconomics ?? {},
                  unitInformation: property?.unitInformation ?? [],
                };
              }
            ) ?? [
              {
                propertyLocation: {},
                propertyInformation: {},
                propertyEconomics: {},
                unitInformation: [{}],
              },
            ],
          };
        }
        setDetailedLoanSizingData(reqBody || intialDetailedLoanSizingState);
      }

      setEvaluationID(data?.data?.["0"]?.data?.evaluationID);
      setSizingType(
        data?.data?.["0"]?.data?.sizingType ===
          SizingTypeValue?.QUICK_LOAN_SIZING
          ? SizingType.QUICK_LOAN_SIZING
          : SizingType.DETAILED_LOAN_SIZING
      );
      setIsApplicationSubmitted(
        data?.data?.["0"]?.data?.isApplicationSubmitted || false
      );
      // setBuyDownOptions(data?.data?.["0"]?.data?.buyDownOptions || []);
      const findCurrentSelection = data?.data?.[
        "0"
      ]?.data?.buyDownOptions?.find(
        (option: any) =>
          option.interestRate ===
          Number(
            data?.data?.[
              "0"
            ]?.data?.currentSelectedInterestRate?.interestRate?.replace(
              "%",
              ""
            ) / 100
          )
      );
      const newCurrentSelectedInterestRate = findCurrentSelection
        ? {
            interestRate: `${(
              findCurrentSelection?.interestRate * 100
            )?.toFixed(3)}%`,
            discountPoints: `${findCurrentSelection?.discountPointPercentage?.toFixed(
              4
            )}`,
            discountPointsWithDollar: formatCurrency(
              findCurrentSelection?.discountPointAmount
            ),
            totalBuyYield: findCurrentSelection?.totalBuyYield,
          }
        : data?.data?.["0"]?.data?.currentSelectedInterestRate;
      setCurrentSelectedInterestRate(newCurrentSelectedInterestRate);
      if (data?.data?.["0"]?.data?.isApplicationSubmitted) {
        setLeverageAndPricingData(
          data?.data?.["0"]?.data?.leverageAndPricingData
        );
      }
      setAutoCalculatedLoanResults(
        data?.data?.["0"]?.data?.autoCalculatedLoanResults
      );
      setAutoCalculatedPropResults(
        data?.data?.["0"]?.data?.autoCalculatedPropResults
      );
      setLoanDetails(data?.data?.["0"]?.data);
      setTcLoanId(data?.data?.["0"]?.data?.tcLoanId);
      setApplicationName(data?.data?.["0"]?.data?.applicationName);
      setShowSendBtn(
        data?.data?.["0"]?.data?.sizingType === "DETAILED_LOAN_SIZING"
      );
      const condoData = {
        ...(data?.data["0"]?.data?.loanCondominium ?? sampleCondoEligibility),
        ["borrowerName"]: decodedTokenData?.name,
      };
      setCondoEligibilityFormData(condoData);
    } catch (err) {
      console.error(err);
    } finally {
      setDynamicLoader(false);
    }
  };

  const handlePromptSubmit = async () => {
    const reqBody = [params.loanId];
    const { data } = await fetchRequestWaiver(reqBody);

    const activeWaiver = data?.data
      ?.map((item: any, index: number) => ({ ...item, originalIndex: index }))
      ?.filter(
        (item: any) =>
          item?.data?.isActive && item?.data?.waiverStatus === "Requested"
      );
    if (activeWaiver?.length) {
      setShowWarningForWaiversSubmit(true);
    }
    setOpenWarningBeforeSubmit(true);

    const isError = validateForm();
    setIsError(isError);
    if (isError) return;
  };

  function getHeadingText(text: ObjectType, card: ObjectType) {
    const isProfitable = Boolean(text?.profitability);
    const profitabilityTest = Boolean(
      testTabData?.find(
        (test: any) =>
          test.title === "Profitability Test" && test.status === "Failed"
      )
    );
    let returnValue = (
      <span className={isProfitable && profitabilityTest ? "red-text" : ""}>
        {text?.[card.label]}
      </span>
    );

    if (card.label === "finalRate") {
      returnValue = (
        <span>
          {failedFinalRate ? (
            <img src={AlertTriangle} alt="failed.svg" />
          ) : null}
          {text?.[card.label]}
        </span>
      );
    }

    if (card.label === "failedTests") {
      const value = text?.[card.label];
      returnValue = (
        <span style={{ color: value && +value > 0 ? "#f75676" : "#2ece89" }}>
          {value}
        </span>
      );
    }
    return returnValue;
  }

  function renderGridLayout(gridEle: any) {
    switch (gridEle?.type) {
      case "table":
        return (
          <div style={{ margin: "15px 0" }}>
            <CustomCard
              headingText={t(gridEle.label)}
              subHeadingText={""}
              loanDetails={loanDetails}
              fullView={gridEle?.fullView ?? false}
              ratesAsOfDate={ratesAsOfDate}
              subComponents={
                <LeverageTable
                  tableHeader={gridEle?.tableHeader ?? []}
                  tableBody={leverageAndPricingData[gridEle.label]?.tableBody}
                  label={gridEle.label}
                  tableClassName={gridEle?.tableClassName ?? ""}
                />
              }
              gridSpace={4}
              data={leverageAndPricingData?.[gridEle.id]}
              headerClass={gridEle?.headerClass ?? ""}
            />
          </div>
        );
      case "disclaimer":
        return loanDetails?.ratesAsOf || ratesAsOfDate ? (
          <Grid container xs={12}>
            <Grid item xs={12} m={expandLeverageAndTests ? 0 : 2}>
              <Disclaimer
                boldText={""}
                children={
                  loanType === "InvestorDSCR" ? (
                    <span>
                      {t("ratesAsOfDate")} &nbsp;
                      <b>
                        {moment(
                          loanDetails?.ratesAsOf || ratesAsOfDate,
                          "DD-MM-YYYY"
                        ).format("MMM DD, YYYY")}
                      </b>
                      &nbsp;
                      {t("daysLockPeriod")}
                    </span>
                  ) : (
                    <span>
                      {t("pricingAsOfDate")} &nbsp;
                      <b>
                        {moment(
                          loanDetails?.ratesAsOf || ratesAsOfDate,
                          "DD-MM-YYYY"
                        ).format("MMM DD, YYYY")}
                      </b>
                    </span>
                  )
                }
                customClass="disclaimerBox"
                showClose={false}
              />
            </Grid>
          </Grid>
        ) : null;
      default:
        return (
          <Grid
            container
            my={2}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {gridEle?.cards
              ?.filter(
                (card: ObjectType) => !card?.hide?.(leverageAndPricingData)
              )
              ?.map((card: any) => (
                <Grid
                  item
                  style={{
                    flexGrow: "1",
                    maxWidth: card?.width || "55%",
                    margin: "4px",
                  }}
                >
                  <CustomCard
                    headingText={getHeadingText(
                      leverageAndPricingData?.[gridEle.id],
                      card
                    )}
                    subHeadingText={t(card.subText)}
                    loanDetails={loanDetails}
                    fullView={gridEle?.fullView ?? false}
                    ratesAsOfDate={ratesAsOfDate}
                    data={leverageAndPricingData?.[gridEle.id]}
                    gridSpace={card?.gridSpace ?? 4}
                    headerClass={gridEle?.headerClass ?? ""}
                    textMaxWidth={card?.textMaxWidth}
                  />
                </Grid>
              ))}
          </Grid>
        );
    }
  }

  function renderRightPanel() {
    // sort and use
    const [tempLPConfigLeft, tempLPConfigRight, tempLpConfigFull] = [
      1, 2, 3,
    ].map((order) =>
      leverageTestsConfig?.filter((ele: ObjectType) => ele?.order === order)
    );
    return (
      <>
        {expandLeverageAndTests ? (
          <Grid container>
            <Grid item xs={expandLeverageAndTests ? 6 : 12} px={2}>
              {tempLPConfigLeft?.map((ele: ObjectType) => {
                return renderGridLayout(ele);
              })}
            </Grid>
            <Grid item xs={expandLeverageAndTests ? 6 : 12} px={2}>
              {tempLPConfigRight?.map((ele: ObjectType) => {
                return renderGridLayout(ele);
              })}
            </Grid>
            <Grid item xs={12} px={2}>
              {tempLpConfigFull?.map((ele: ObjectType) => {
                return renderGridLayout(ele);
              })}
            </Grid>
          </Grid>
        ) : (
          <>
            {leverageTestsConfig?.map((ele: ObjectType, idx: number) => {
              switch (ele?.type) {
                case "table":
                  return (
                    <Grid container key={idx}>
                      <Grid
                        item
                        xs={expandLeverageAndTests ? 6 : 12}
                        m={2}
                        style={{ margin: "12px" }}
                      >
                        <CustomCard
                          headingText={t(ele.label)}
                          subHeadingText={""}
                          loanDetails={loanDetails}
                          fullView={ele?.fullView ?? false}
                          ratesAsOfDate={ratesAsOfDate}
                          subComponents={
                            <LeverageTable
                              tableHeader={ele?.tableHeader ?? []}
                              tableBody={
                                leverageAndPricingData[ele.label]?.tableBody
                              }
                              label={ele.label}
                              tableClassName={ele?.tableClassName ?? ""}
                            />
                          }
                          gridSpace={4}
                          data={leverageAndPricingData?.[ele.id]}
                          headerClass={ele?.headerClass ?? ""}
                        />
                      </Grid>
                    </Grid>
                  );
                case "disclaimer":
                  return loanDetails?.ratesAsOf || ratesAsOfDate ? (
                    <Grid container key={idx}>
                      <Grid item xs={expandLeverageAndTests ? 6 : 12} m={2}>
                        <Disclaimer
                          boldText={""}
                          children={
                            loanType === "InvestorDSCR" ? (
                              <span>
                                {t("ratesAsOfDate")} &nbsp;
                                <b>
                                  {moment(
                                    isApplicationSubmitted
                                      ? loanDetails?.ratesAsOf
                                      : ratesAsOfDate || loanDetails?.ratesAsOf,
                                    "DD-MM-YYYY"
                                  ).format("MMM DD, YYYY")}
                                </b>
                                &nbsp;
                                {t("daysLockPeriod")}
                              </span>
                            ) : (
                              <span>
                                {t("pricingAsOfDate")} &nbsp;
                                <b>
                                  {moment(
                                    loanDetails?.ratesAsOf || ratesAsOfDate,
                                    "DD-MM-YYYY"
                                  ).format("MMM DD, YYYY")}
                                </b>
                              </span>
                            )
                          }
                          customClass="disclaimerBox"
                          showClose={false}
                          isRatesAsOff={true}
                        />
                      </Grid>
                    </Grid>
                  ) : null;
                default:
                  return (
                    <Grid
                      container
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      key={ele?.type}
                    >
                      {ele?.cards
                        ?.filter(
                          (card: ObjectType) =>
                            !card?.hide?.(leverageAndPricingData)
                        )
                        ?.map((card: ObjectType, lIdx: number) => {
                          return (
                            <Grid
                              item
                              style={{
                                flexGrow: "1",
                                maxWidth: card?.width || "55%",
                                margin: "4px",
                              }}
                              key={lIdx}
                              xs={expandLeverageAndTests ? 6 : 12}
                            >
                              <CustomCard
                                headingText={getHeadingText(
                                  leverageAndPricingData?.[ele.id],
                                  card
                                )}
                                subHeadingText={t(card.subText)}
                                loanDetails={loanDetails}
                                fullView={ele?.fullView ?? false}
                                ratesAsOfDate={ratesAsOfDate}
                                data={leverageAndPricingData?.[ele.id]}
                                gridSpace={card?.gridSpace ?? 4}
                                headerClass={ele?.headerClass ?? ""}
                                showFailed={false}
                                textMaxWidth={card?.textMaxWidth}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  );
              }
            })}
          </>
        )}
      </>
    );
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function handleTabChange(event: React.SyntheticEvent, newValue: number) {
    setCurrentTab(newValue);
    setExpandAvailableProgramsView(
      newValue === 2 && loanType === "InvestorDSCR"
    );
  }

  async function handleSubmitApplication() {
    setIsLoading(true);
    try {
      // public api changes
      // const updateLoanURL = getURL("public-api/aggregate/loans");

      const loanTypeId = loanType === "InvestorDSCR" ? 1 : 2;
      const orgId = getCookie("orgId") || dynamicConfig?.clientId;

      const partyId = getCookie("partyId") || dynamicConfig?.partyId;
      const createPayload = {
        loanTypeId,
        originatorPartyId: orgId || "",
        loanType: loanType === "InvestorDSCR" ? "30 Year Loan" : "Bridge Loan",
        stage: "FES",
        loanOrigin: "Online Sizer",
        fundingType: "normal",
        servicerPartyId: "",
        servicerName: "BSI",
      };

      const createResult: any = await postCreateLoan(
        createPayload,
        realmHeaders
      );
      let currentRateData: any = { ...currentSelectedInterestRate };
      const selectedBuyDownOptions = buyDownOptions?.find((item: any) => {
        const itemInterestRate = item?.interestRate
          ? parseFloat(item.interestRate) * 100
          : null;
        const selectedInterestRate = currentSelectedInterestRate?.interestRate
          ? parseFloat(
              currentSelectedInterestRate?.interestRate.replace("%", "")
            )
          : null;

        if (itemInterestRate === null || selectedInterestRate === null) {
          return false;
        }
        return itemInterestRate.toFixed(4) === selectedInterestRate.toFixed(4);
      });
      const waiverRequest = await fetchRequestWaiver([params.loanId]);
      currentRateData = await calculateCurrentSelectedInterestRate(
        {
          ...currentSelectedInterestRate,
          discountPoints:
            selectedBuyDownOptions?.discountPointPercentage != null
              ? `${selectedBuyDownOptions?.discountPointPercentage?.toFixed(
                  4
                )} %`
              : null,
          discountPointsWithDollar:
            selectedBuyDownOptions?.discountPointAmount != null
              ? formatCurrency(selectedBuyDownOptions?.discountPointAmount)
              : null,
        },
        waiverRequest,
        buyDownOptions
      );

      if (createResult) {
        const updatePayload = normalizeSubmitApplicationLoanData(
          {
            borrowerInformation: detailedLoanSizingData?.borrowerInformation,
            loanInformation: detailedLoanSizingData?.loanInformation,
            loanEconomics: {
              ...detailedLoanSizingData?.loanEconomics,
              interestRate: parseFloat(
                currentSelectedInterestRate?.interestRate?.replace("%", "")
              ),
              originationPoints:
                leverageAndPricingData?.leverageCards?.originationPoints,
            },
            currentSelectedInterestRate: currentRateData,
            defaultOriginatorFee:
              originatorAccountMappingData?.termSheetDynamicFields,
          },
          createResult?.data?.loanId,
          loanTypeId,
          condoEligibilityFormData,
          autoCalculatedLoanResults,
          fetchData,
          loanDetails,
          latestTermSheetVersion
        );
        const propertyPayload = normalizeSubmitApplicationPropertyData(
          detailedLoanSizingData?.propertyDetails,
          loanDetails,
          createResult?.data?.loanId,
          loanTypeId,
          autoCalculatedPropResults
        );

        // const updatePropertyURL = getURL(
        //   `public-api/aggregate/loan_property/${createResult?.data?.loanId}/properties?stage=FES`
        // );

        // need to ask
        await postUpdateLoanUrl(updatePayload, realmHeaders);
        await postUpdatePropertyUrl(
          createResult?.data?.loanId,
          propertyPayload,
          realmHeaders
        );

        setTcLoanId(createResult?.data?.loanId);
        setShowSnackBar(true);
        setSnackBarMsg("loanSaved");
        if (loanDetails?.sizingType === SizingTypeValue?.DETAILED_LOAN_SIZING) {
          await autoGenerateTermSheet();
        }
        await handleSaveApplication(true, createResult?.data?.loanId);
      } else {
        setShowSnackBar(true);
        setSnackBarMsg("loanSavedFailed");
      }
    } catch (err) {
      console.error(err);
      setShowSnackBar(true);
      setSnackBarMsg("loanSavedFailed");
    } finally {
      setIsLoading(false);
      setOpenWarningBeforeSubmit(false);
    }
  }

  function renderSubmitApplicationModal() {
    return (
      <Grid sx={{ padding: "24px" }}>
        {noAssignedPartner === "eligibilityAndPricingText" || isError ? (
          <div>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "30px",
              }}
            >
              {t("unableToSubmit")}
            </p>
            <p
              style={{
                color: "#3C3C3C",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "22px",
                textAlign: "left",
              }}
            >
              {t("submitValidationErrorMessage")}
            </p>
            <LoadingButton
              size="large"
              variant="contained"
              loading={isLoading}
              onClick={() => setOpenWarningBeforeSubmit(false)}
              style={{ marginTop: "16px" }}
            >
              Ok
            </LoadingButton>
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "8px",
              }}
            >
              <img src={WarnigIconYellow} />
              <span
                style={{ fontWeight: 600, fontSize: "18px", margin: "0px 8px" }}
              >
                {t("wait")}
              </span>
              <img src={WarnigIconYellow} />
            </div>
            <div>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "22px",
                  textAlign: "center",
                  color: "#3C3C3C",
                }}
              >
                {t("submitConfirmationBody1")}
              </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "22px",
                  textAlign: "center",
                  color: "#3C3C3C",
                }}
              >
                {t("submitConfirmationBody2")}
              </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "22px",
                  textAlign: "center",
                  color: "#3C3C3C",
                }}
              >
                {t("submitConfirmationBody3")}
              </span>
            </div>
            {showWarningForWaiversSubmit ? (
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "22px",
                  textAlign: "center",
                  color: "#3C3C3C",
                  marginTop: "12px",
                  background: "#fffaed",
                  padding: "8px 4px",
                }}
              >
                <span>
                  <b>{t("note")}: </b>
                </span>
                <span>{t("activeRequestedWaiverConfirmation")}</span>
              </div>
            ) : null}
            {loanType === "InvestorDSCR" && assignedOrTakeOutPartnerId ? (
              <div
                style={{
                  backgroundColor: "#F8F9FD",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "8px 10px",
                  borderRadius: "6px",
                  margin: "24px 0px 32px 0px",
                }}
              >
                <span
                  style={{
                    color: "#40128B",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0.5px",
                    textAlign: "left",
                  }}
                >
                  Program Selected:
                </span>
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "24px",
                    letterSpacing: "0.5px",
                    textAlign: "left",
                    color: "#1C1C1C",
                  }}
                >
                  {DSCROrgPartnerMapping[assignedOrTakeOutPartnerId]}
                </span>
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "16px 0px",
              }}
            >
              <LoadingButton
                fullWidth
                size="large"
                variant="contained"
                loading={isLoading}
                onClick={() => handleSubmitApplication()}
              >
                Yes, Submit
              </LoadingButton>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingButton
                fullWidth
                size="large"
                variant="outlined"
                loading={isLoading}
                onClick={() => setOpenWarningBeforeSubmit(false)}
              >
                Go Back
              </LoadingButton>
            </div>
          </div>
        )}
      </Grid>
    );
  }

  const isRiskPricing = useMemo(() => {
    if (leverageAndPricingData?.leverageCards?.pricingType === "RISK") {
      return "riskBasedPricing";
    }
    if (leverageAndPricingData?.leverageCards?.pricingType === "EXPERIENCE") {
      return "experienceBasedPricing";
    }
    return "";
  }, [leverageAndPricingData]);

  const expandTabs = () => {
    setExpandLeverageAndTests(!expandLeverageAndTests);
  };

  const fetchGuidelines = async (
    newSizingType?: string,
    newLoanDetails?: any,
    isInitialLoad?: boolean
  ) => {
    try {
      const { setRatesAsOfDate, setCriticalTests } = loanContextData;

      const publicAPI2Headers = {
        accept: "application/json",
        "kc-realm": dynamicConfig?.ViteAppKcRealm,
      };
      const loanData = {
        ...newLoanDetails,
        quickLoanSizingData,
        detailedLoanSizingData,
      };

      const isDSCR = loanType === "InvestorDSCR";
      const loanTypeStr = loanType === "InvestorDSCR" ? "30Year" : "BRIDGE";

      if (!isApplicationSubmitted || dynamicConfig?.allowEditAfterSubmit) {
        const data = await fetchGuidelinesAndBuyDownOptions({
          loanDetails: newLoanDetails || loanDetails,
          quickLoanSizingData,
          detailedLoanSizingData,
          loanId,
          dynamicConfig,
          selectedSizingType: selectedSizingType || newSizingType,
          isInitialLoad,
        });
        if (Object.keys(data ?? {}).length) {
          let result: ObjectType = data;
          let tempResultKeys: ObjectType = {};
          let tempLoanResult: ObjectType = {};
          let tempPropertyResult: ObjectType = { ...autoCalculatedPropResults };
          let tempCriticalTests: ObjectType = {};

          const assignedPartner = data?.assignedPartner || "";
          const partnerId =
            loanDetails?.takeoutPartnerStatus === "OVERRIDDEN"
              ? loanDetails?.takeOutPartner
              : assignedPartner || "";
          Object.keys(data?.attributes).forEach((key) => {
            tempCriticalTests[key] =
              data?.attributes?.[key]?.criticalTestLoanEligibility;
          });
          if (partnerId) {
            result = data?.tpResultMap?.[partnerId];
            tempResultKeys = Object.keys(result?.loanResult);
            tempLoanResult = result?.loanResult[tempResultKeys[0]];
            tempPropertyResult = result?.propertiesResults;
            setAssignedPartner(partnerId ?? "");
            setLoanDetails({ ...newLoanDetails, takeOutPartner: partnerId });
            getTestTabResultData(
              data?.tpResultMap,
              loanData,
              publicAPI2Headers,
              isDSCR,
              tempLoanResult,
              loanTypeStr,
              fetchData,
              loanContextData,
              RootContextData,
              templateResponseData,
              setTemplateResponseData,
              dynamicConfig,
              templateLabels,
              assignedPartner,
              tempCriticalTests
            );
          } else {
            setAssignedPartner("");
            getTestTabResultData(
              data?.tpResultMap,
              loanData,
              publicAPI2Headers,
              isDSCR,
              tempLoanResult,
              loanTypeStr,
              fetchData,
              loanContextData,
              RootContextData,
              templateResponseData,
              setTemplateResponseData,
              dynamicConfig,
              templateLabels,
              assignedPartner,
              tempCriticalTests
            );
            const currentKey = Object.keys(data?.tpResultMap)[0];
            result = data?.tpResultMap?.[currentKey];
            tempResultKeys = Object.keys(result?.loanResult ?? {});
            tempLoanResult = result?.loanResult?.[tempResultKeys?.[0]];
            tempPropertyResult = result?.propertiesResults;
          }
          // Used to populate the autocalculated fields
          const finalLoanData: any = normalizeResultLoanData(
            {
              ...loanDetails,
              ...quickLoanSizingData,
              ...detailedLoanSizingData,
            },
            tempLoanResult,
            isDSCR ? "30Year" : "Bridge",
            tempPropertyResult,
            result?.propertiesResults,
            fetchData
          );

          let asOfDate =
            tempLoanResult?.ruleVersion?.RATE_SHEET?.substr(1, 8) ?? "";

          if (asOfDate) {
            asOfDate = moment(asOfDate, "DD-MM-YY").format("DD-MM-YYYY");
          }

          const { tempLoanDetails, propertyResult } = finalLoanData;
          setAutoCalculatedLoanResults({ ...tempLoanDetails });
          setAutoCalculatedPropResults([...propertyResult]);
          setRulesResponse(data);
          setRatesAsOfDate(asOfDate);
          setPartnersResults(data?.tpResultMap);
          setCriticalTests(data?.attributes);
          setBuyDownOptions(tempLoanResult?.buyDownOptions);
          setEligiblePartnerResults(data);
          return { rulesData: data, asOfDate, criticalTests: data?.attributes };
        }
      }
    } catch (err) {
      console.error("Something went wrong while running rules. ERROR:", err);
    }
  };

  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackBar(false);
    setSnackBarMsg("");
  };

  const renderSnackBar = () => {
    const style =
      snackBarMsg === "loanSavedFailed" ? errorSnackBar : snackbarStyle;

    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={true}
        onClose={handleClose}
        autoHideDuration={5000}
        ContentProps={{ style }}
        message={
          <div className="flex-display">
            <img
              src={
                snackBarMsg === "loanSavedFailed"
                  ? InfoErrorIcon
                  : CheckedCircle
              }
              className="mr-12"
            />
            <span>
              {t(
                snackBarMsg === "loanSavedFailed"
                  ? "loanSavedFailed"
                  : t(snackBarMsg).replace("replace", tcLoanId)
              )}
            </span>
          </div>
        }
      />
    );
  };

  // Condo Eligibility
  function preLoadCondoEligibleConfig() {
    let tempConfig = { ...condoEligibilityConfig };
    tempConfig = {
      ...tempConfig,
      fields: {
        ...tempConfig?.fields,
        ["fee"]: tempConfig?.fields?.fee?.map((feeEle: any) => {
          if (feeEle?.label === "ownerControlStartDate") {
            if (condoEligibilityFormData?.["hoaUnderOwnerControl"] === "Yes") {
              feeEle.isVisible = true;
            } else {
              feeEle.isVisible = false;
            }
          }
          if (feeEle.label === "indivdualOwnedUnits") {
            if (
              condoEligibilityFormData?.[
                "individualOwnershipAboveTwentyFive"
              ] === "Yes"
            ) {
              feeEle.isVisible = true;
            } else {
              feeEle.isVisible = false;
            }
          }
          if (feeEle.label === "hoawithLitOrBankruptcyDesc") {
            if (
              condoEligibilityFormData?.["hoawithLitOrBankruptcy"] === "Yes"
            ) {
              feeEle.isVisible = true;
            } else {
              feeEle.isVisible = false;
            }
          }
          if (feeEle.label === "restrictOwnerToRentOutDesc") {
            if (condoEligibilityFormData?.["restrictOwnerToRent"] === "Yes") {
              feeEle.isVisible = true;
            } else {
              feeEle.isVisible = false;
            }
          }
          return { ...feeEle };
        }),
      },
    };
    return tempConfig;
  }

  async function calculateEligibility() {
    try {
      const reqBody = {
        data: {
          loan: {
            loanCondominium: {
              monthlyHOAFee: condoEligibilityFormData?.monthlyHOAFee ?? null,
              hoaDuesAboveFifteen:
                condoEligibilityFormData?.hoaDuesAboveFifteen ?? null,
              hoaDuesAboveTwenty:
                condoEligibilityFormData?.hoaDuesAboveTwenty ?? null,
              reserveFundsForOneYear:
                condoEligibilityFormData?.reserveFundsForOneYear ?? null,
              reserveFundsForTwoYears:
                condoEligibilityFormData?.reserveFundsForTwoYears ?? null,
              isProjectCompleted:
                condoEligibilityFormData?.isProjectCompleted ?? null,
              subjectToAdditionalPhasing:
                condoEligibilityFormData?.subjectToAdditionalPhasing ?? null,
              soldPercentage: condoEligibilityFormData?.soldPercentage ?? null,
              hoaUnderOwnerControl:
                condoEligibilityFormData?.hoaUnderOwnerControl ?? null,
              simpleEstateOwnership:
                condoEligibilityFormData?.simpleEstateOwnership ?? null,
              ownerOccupiedUnits:
                condoEligibilityFormData?.ownerOccupiedUnits ?? null,
              renterOccupiedUnits:
                condoEligibilityFormData?.renterOccupiedUnits ?? null,
              renterOccupiedPercentage:
                condoEligibilityFormData?.renterOccupiedPercentage ?? null,
              individualOwnershipAboveTwentyFive:
                condoEligibilityFormData?.individualOwnershipAboveTwentyFive ??
                null,
              masterAssurancePolicy:
                condoEligibilityFormData?.masterAssurancePolicy ?? null,
              projectHasMobileHomes:
                condoEligibilityFormData?.projectHasMobileHomes ?? null,
              otherSourcesIncomeMoreThanTwenty:
                condoEligibilityFormData?.otherSourcesIncomeMoreThanTwenty ??
                null,
              commercialPurposesAreaMoreThanForty:
                condoEligibilityFormData?.commercialPurposesAreaMoreThanForty ??
                null,
              restrictOwnerToRent:
                condoEligibilityFormData?.restrictOwnerToRent ?? null,
              documentsWithSEC:
                condoEligibilityFormData?.documentsWithSEC ?? null,
              hoawithLitOrBankruptcy:
                condoEligibilityFormData?.hoawithLitOrBankruptcy ?? null,
              ownerControlStartDate:
                condoEligibilityFormData?.ownerControlStartDate
                  ? getFormattedDate(
                      condoEligibilityFormData?.ownerControlStartDate
                    )
                  : null,
              indivdualOwnedUnits:
                condoEligibilityFormData?.indivdualOwnedUnits ?? null,
              hoawithLitOrBankruptcyDesc:
                condoEligibilityFormData?.hoawithLitOrBankruptcyDesc ?? null,
              restrictOwnerToRentOutDesc:
                condoEligibilityFormData?.restrictOwnerToRentOutDesc ?? null,
              condoProjectName:
                condoEligibilityFormData?.condoProjectName ?? null,
              streetAddressLine1:
                condoEligibilityFormData?.condoStreetAddress ?? null,
              noOfUnits: condoEligibilityFormData?.unit ?? null,
              state: condoEligibilityFormData?.state ?? null,
              city: condoEligibilityFormData?.city ?? null,
              postalCode: condoEligibilityFormData?.zip ?? null,
            },
          },
        },
        templateKeys: ["rule.InvestorDSCR.condominium"],
      };

      const latestVersioningDate = "12-11-2024";
      const { data } = await postCalculateEligibility(reqBody, realmHeaders);
      const condoValue = data[`rule.InvestorDSCR.condominium`];
      const isNew: any = moment(
        latestVersioningDate,
        "DD-MM-YYYY"
      ).isSameOrBefore(moment(loansApiResponse?.createdAt));
      let condoValueDisplay = "";
      switch (condoValue) {
        case "Doesn't meet TRK Min Requirements": {
          condoValueDisplay = "Not Eligible";
          break;
        }
        case "Meets TRK Standard Requirements": {
          condoValueDisplay = isNew ? "Warrantable" : "Standard";
          break;
        }
        case "Only Meets TRK Min Requirements": {
          condoValueDisplay = isNew ? "Non-Warrantable" : "Min";
          break;
        }
        default:
      }
      setLoanDetails({
        ...loanDetails,
        ["loanInformation"]: {
          ...loanDetails.loanInformation,
          condoEligibility: condoValueDisplay,
        },
        loanCondominium: reqBody?.data?.loan?.loanCondominium,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setCondoEligibility(false);
    }
  }

  function handleCondoBtnClick(button: any) {
    if (button?.type === "contained") {
      calculateEligibility();
    } else if (button?.label === "download") {
      const tempLoanDetails = {
        ...loanDetails,
        loanCondo: condoEligibilityFormData,
      };
      exportHTML({ loanDetails: tempLoanDetails, loanId, loanType });
    } else {
      setCondoEligibility(false);
    }
  }

  function CondoEligibiltyModalContent() {
    const config = preLoadCondoEligibleConfig();
    const configKeys = Object.keys(config.fields);
    return (
      <Grid container className="p-12">
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
          className="mb-8"
        >
          <span>{t("toorakCondominimumQuestionnaire")}</span>
          <IconButton
            onClick={() => setCondoEligibility(false)}
            data-testid="condo-close-icon"
          >
            <img src={CloseIcon} />
          </IconButton>
        </Grid>
        {configKeys.map((key: string, idx: number) => {
          return (
            <Grid container key={idx} className="mb-8">
              {condoEligibilityConfig?.fields?.[key]
                ?.filter((ele: any) => ele.isVisible)
                .map((config: ObjectType, i: number) => {
                  return (
                    <Grid
                      item
                      xs={config?.columnSplit}
                      key={i}
                      className="mb-8 mt-12"
                    >
                      {renderFieldUI(
                        config,
                        i,
                        condoEligibilityFormData?.[config.label],
                        handleCondoEligibilityModalChange,
                        loanType,
                        theme,
                        isApplicationSubmitted,
                        t,
                        loanContextData,
                        ""
                      )}
                    </Grid>
                  );
                })}
              <Grid item xs={12} className="mb-8 mt-12">
                <Divider />
              </Grid>
            </Grid>
          );
        })}
        <Divider />
        {condoEligibilityConfig.buttons.map((button: any, index: number) => {
          return (
            <Grid item xs={button.gridSize} key={index}>
              <Button
                variant={button.type}
                onClick={() => handleCondoBtnClick(button)}
                data-testid={button?.label}
              >
                {t(button?.label)}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  async function getTermSheetVersionsForLoan() {
    try {
      const realmHeaders = {
        "kc-realm": dynamicConfig?.ViteAppKcRealm,
      };
      const response = await getTermSheetVersions(realmHeaders, loanId);
      setTermSheetVersions(
        response?.data?.data?.sort((a: any, b: any) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        })
      );
    } catch (err) {
      console.error(err);
    }
  }

  async function updateChips() {
    const waiverRequest = await fetchRequestWaiver([params.loanId]);
    const activeWaiverRequestStatus = waiverRequest?.data?.data?.find(
      (item: any) => item?.data?.isActive
    )?.data?.waiverStatus;
    const tempChipData: string[] = [
      loanDetails?.productType,
      loanDetails?.propertyType?.includes("Mixed")
        ? t("multiFamilyMixedUseLabel")
        : loanDetails?.propertyType,
    ];

    const activeWaiver = waiverRequest?.data?.data?.filter(
      (item: any) => item?.data?.isActive
    );

    if (activeWaiver?.[0]?.data?.waiverStatus) {
      const status = activeWaiver[0]?.data?.waiverStatus;
      tempChipData.push(`Price Exception: ${status}`);
      setPriceExceptionStatus(status);
    }

    if (assignedPartner) {
      tempChipData.push(DSCROrgPartnerMapping[assignedPartner]);
    }
    if (tempChipData?.length)
      setChipsData(tempChipData?.filter((str: string) => str));
  }

  function updateLeverageWithBuyDownOptions(data?: any) {
    const { originationPoints } = data ?? {};
    if (
      Object.keys(currentSelectedInterestRate ?? {})?.length &&
      (buyDownOptions ?? [])?.length &&
      Object.keys(leverageAndPricingData ?? {})?.length
    ) {
      const tempCurrentRateData = buyDownOptions?.find(
        (item: any) =>
          (item?.interestRate * 100).toFixed(3) ===
          parseFloat(
            currentSelectedInterestRate?.interestRate?.replace("%", "")
          ).toFixed(3)
      );
      const finalPriceArr = [
        ...(leverageAndPricingData?.finalPriceTable?.tableBody ?? []),
      ]?.filter((ele: any) => ele[0] !== "basePrice");

      if (tempCurrentRateData) {
        const newLevAndPricData = {
          ...(leverageAndPricingData ?? {}),
          ["leverageCards"]: {
            ...(leverageAndPricingData?.leverageCards ?? {}),
            ["interestRate"]: `${(
              tempCurrentRateData?.interestRate * 100
            )?.toFixed(3)}%`,
            ["dscr"]: tempCurrentRateData?.dscr,
            ["finalPrice"]: tempCurrentRateData?.finalToorakPrice,
            ["maxLoanAmount"]: sanitizeData(
              tempCurrentRateData?.toorakLoanAmount,
              true,
              2
            ),
            originationPoints: originationPoints,
          },
          requiredReserves: {
            ...(leverageAndPricingData?.requiredReserves ?? {}),
            dscr: tempCurrentRateData?.dscr,
            minimumRequiredReserves:
              tempCurrentRateData?.minimumBorrowerLiquidity
                ? `${sanitizeData(
                    tempCurrentRateData?.minimumBorrowerLiquidity,
                    true,
                    2
                  )}`
                : "",
          },
          leverages: {
            tableBody: (
              leverageAndPricingData?.leverages?.tableBody ?? []
            )?.map((body: any) => {
              if (body) {
                if (body?.id === "max") {
                  return {
                    ...body,
                    data: [
                      "Max",
                      getPercentageValue(tempCurrentRateData?.maxAsIsLTV),
                      getPercentageValue(tempCurrentRateData?.maxAsIsLTV),
                    ],
                  };
                }
                if (body?.id === "adjustedMax") {
                  return {
                    ...body,
                    data: [
                      "Adjusted Max",
                      getPercentageValue(
                        tempCurrentRateData?.toorakAdjustedAsIsLtv
                      ),
                      getPercentageValue(
                        tempCurrentRateData?.toorakAdjustedAsIsLtv
                      ),
                    ],
                  };
                }
                return {
                  ...body,
                  data: [
                    "Requested",
                    getPercentageValue(
                      getTableValue(
                        getValue(
                          Number(body?.data?.[1]?.replace(/[+%]/g, "").trim()),
                          null
                        ),
                        tempCurrentRateData?.toorakAdjustedAsIsLtv * 100,
                        false
                      ),
                      false,
                      2,
                      false
                    ),
                    getPercentageValue(
                      getTableValue(
                        getValue(
                          Number(body?.data?.[2]?.replace(/[+%]/g, "").trim()),
                          null
                        ),
                        tempCurrentRateData?.toorakAdjustedAsIsLtv * 100,
                        false
                      ),
                      false,
                      2,
                      false
                    ),
                  ],
                };
              }
            }),
          },
          finalPriceTable: {
            ...(leverageAndPricingData?.finalPriceTable ?? {}),
            tableBody: [
              ...finalPriceArr,
              [finalPricingTables?.basePrice, tempCurrentRateData?.basePrice],
            ],
          },
        };
        setLeverageAndPricingData(newLevAndPricData);
      }
    }
  }

  useEffect(() => {
    const originationPoints =
      leverageAndPricingData?.leverageCards?.originationPoints;
    updateLeverageWithBuyDownOptions({ originationPoints });
  }, [
    buyDownOptions,
    currentSelectedInterestRate,
    assignedPartner,
    leverageAndPricingData?.leverageCards?.dscr,
  ]);

  useEffect(() => {
    const prepaymentPenaltyTypeMonths =
      loanDetails?.sizingType === SizingTypeValue?.QUICK_LOAN_SIZING
        ? quickLoanSizingData?.loanEconomics?.prepaymentPenaltyTypeMonths
        : detailedLoanSizingData?.loanEconomics?.prepaymentPenaltyTypeMonths;
    if (
      originatorAccountMappingData?.termSheetDynamicFields?.length &&
      !leverageAndPricingData?.leverageCards?.originationPoints
    ) {
      const originationPoints =
        originatorAccountMappingData?.termSheetDynamicFields?.find(
          (filed: any) => filed?.field === "origination_points"
        );
      if (originationPoints) {
        setLeverageAndPricingData((previous: any) => {
          return {
            ...(previous ?? {}),
            ["leverageCards"]: {
              ...(previous?.leverageCards ?? {}),
              originationPoints: originationPoints?.defaultValue,
            },
          }
        });
      }
    }
    if (prepaymentPenaltyTypeMonths || prepaymentPenaltyTypeMonths === 0 || currentSelectedInterestRate?.interestRate) {
      setLeverageAndPricingData((previous: any) => {
        return {
          ...(previous ?? {}),
          ["leverageCards"]: {
            ...(previous?.leverageCards ?? {}),
            prepaymentPenaltyTypeMonths: prepaymentPenaltyTypeMonths,
            prepayPenaltyType:
              prePayTypeToMonthsMap?.[prepaymentPenaltyTypeMonths ?? 0],
          },
        }
      });
    }
  }, [
    quickLoanSizingData,
    detailedLoanSizingData,
    originatorAccountMappingData,
    currentSelectedInterestRate
  ]);

  useEffect(() => {
    getLoanDataBasedOnLoanId();
  }, [loanId, templateLabels, loanType]);

  useEffect(() => {
    setShowBanners(dynamicConfig?.ViteAppKcRealm === "bplsizer");
    let lpConfig: any = {};
    const tabName = currentTab;
    switch (tabName) {
      case 0:
        lpConfig = getLeveragePricingTestsConfig(
          "DSCR",
          0,
          loanDetails?.sizingType
        );
        break;
      case 1:
        lpConfig = getLeveragePricingTestsConfig("DSCR", 1);
        break;
    }
    setLeverageTestsConfig(lpConfig.components);
  }, [dynamicConfig, loanDetails?.sizingType]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        dynamicConfig?.allowEditAfterSubmit ||
        !loanDetails?.isApplicationSubmitted
      ) {
        fetchGuidelines(
          loanDetails?.sizingType === SizingTypeValue?.QUICK_LOAN_SIZING
            ? SizingTypeValue.QUICK_LOAN_SIZING
            : SizingTypeValue.DETAILED_LOAN_SIZING,
          loanDetails,
          false
        );
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [newDebouncedValue, loanDetails?.sizingType]);

  useEffect(() => {
    if (loanDetails?.sizingType === SizingTypeValue.QUICK_LOAN_SIZING) {
      setQuickLoanSizingData({
        ...quickLoanSizingData,
        loanOutputs: {
          ...quickLoanSizingData?.loanOutputs,
          dscr: leverageAndPricingData?.leverageCards?.dscr,
        },
        loanEconomics: {
          "prepaymentPenaltyTypeMonths": 0,
          ...(quickLoanSizingData?.loanEconomics ?? {})
      }
      });
    }
    if (
      originatorAccountMappingData?.termSheetDynamicFields?.length &&
      !leverageAndPricingData?.leverageCards?.originationPoints
    ) {
      const originationPoints =
        originatorAccountMappingData?.termSheetDynamicFields?.find(
          (filed: any) => filed?.field === "origination_points"
        );
      if (originationPoints) {
        updateLeverageWithBuyDownOptions({
          originationPoints: originationPoints?.defaultValue,
        });
      }
    }
  }, [
    leverageAndPricingData?.leverageCards?.dscr,
    originatorAccountMappingData,
  ]);

  useEffect(() => {
    setCurrentPath(window.location.pathname);
    getTermSheetVersionsForLoan();
    const tempCondoEligibiltyConfig: ObjectType = getCondoEligibilityFields();
    setCondoEligibilityConfig(tempCondoEligibiltyConfig);
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      const confirmationMessage = t("reloadText");
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };
    setExpandAvailableProgramsView(false);

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (decodedTokenData) {
      setCondoEligibilityFormData({
        ...condoEligibilityFormData,
        ["borrowerName"]: decodedTokenData?.name,
      });
    }
  }, [decodedTokenData]);

  useEffect(() => {
    updateChips();
  }, [loanDetails, assignedPartner]);

  return (
    <div>
      <Header
        showSubmitLoanBtn={false}
        showSaveApplicationBtn={true}
        showBackToListBtn={false}
        showLinkBtn={false}
        showDownloadBtn={false}
        showBorder={true}
        validateForm={validateForm}
        handleSaveApplication={handleSaveApplication}
        setApplicationName={setApplicationName}
        applicationName={applicationName}
        isLoading={isLoading}
        openSaveApplicationModal={openSaveApplicationModal}
        setOpenSaveApplicationModal={setOpenSaveApplicationModal}
        evaluationID={evaluationID}
        handleSubmitApplication={handlePromptSubmit}
        disableSubmitApplication={disableSubmitApplication}
        disableSaveApplication={
          dynamicConfig?.allowEditAfterSubmit ? false : isApplicationSubmitted
        }
        applicationError={applicationError}
        setApplicationError={setApplicationError}
        showCreateTermSheet={showTermSheet}
        showKDMButton={showKeyDealMetrics}
        showConvertBtn={
          loanDetails?.sizingType === SizingTypeValue.QUICK_LOAN_SIZING
        }
        showSendBtn={
          loanDetails?.sizingType === SizingTypeValue.DETAILED_LOAN_SIZING
        }
        showMenuIcon={[
          SizingTypeValue.QUICK_LOAN_SIZING,
          SizingTypeValue.DETAILED_LOAN_SIZING,
        ].includes(loanDetails?.sizingType)}
        handleConvertion={handleConvertion}
        isApplicationSubmitted={isApplicationSubmitted}
      />
      <div style={{ display: "flex" }}>
        <div
          style={
            !expandAvailableProgramsView
              ? {
                  display: "block",
                  borderRight: "1px solid #9FAAA9",
                  width: "60%",
                }
              : {
                  display: "none",
                  borderRight: "1px solid #9FAAA9",
                  width: "65%",
                }
          }
        >
          <Banners
            expandAvailableProgramsView={expandAvailableProgramsView}
            expandLeverageAndTests={expandLeverageAndTests}
            showLoanDetails={showLoanDetails}
            applicationName={applicationName}
            evaluationID={evaluationID}
            chipsData={chipsData}
            noAssignedPartner={noAssignedPartner}
            disclaimer={disclaimer}
            showBanners={showBanners}
          />
          {/* Components */}
          <LoanFieldsUIWrapper />
        </div>
        <div
          style={
            !expandAvailableProgramsView
              ? {
                  display: "block",
                  padding: "8px",
                  width: "40%",
                  background: "#F4F6FA",
                }
              : {
                  display: "block",
                  padding: "8px",
                  width: "90%",
                  background: "#F4F6FA",
                }
          }
        >
          <div className="div-space-between align-flex-start border-bottom-divider">
            <Tabs value={currentTab} onChange={handleTabChange}>
              {TabLists.map((tab: any, idx: number) => {
                if (
                  tab.isVisible({
                    loanType,
                    fundingType:
                      orgFundingType === "undefined"
                        ? "normal"
                        : orgFundingType,
                    allowPartnerSelection: dynamicConfig?.allowPartnerSelection,
                    loanDetails,
                    sizingType: loanDetails?.sizingType,
                  })
                ) {
                  const Icon = tab.icon;
                  return (
                    <Tab
                      key={idx}
                      value={idx}
                      icon={
                        <Tooltip
                          title={t(tab.label)}
                          slots={{
                            transition: Fade,
                          }}
                        >
                          <img src={Icon} alt="icon" />
                        </Tooltip>
                      }
                      label={
                        <span
                          style={{
                            display: currentTab === idx ? "block" : "none",
                            transition: "all 0.3s",
                          }}
                        >
                          {t(tab.label)}
                        </span>
                      }
                      sx={{
                        "& .MuiTab-wrapper": {
                          flexDirection: "column",
                          alignItems: "center",
                          transition: "all 0.3s",
                        },
                        "& .MuiTab-wrapper span": {
                          display: currentTab === idx ? "block" : "none",
                        },
                        "& .MuiTab-wrapper img": {
                          marginBottom: currentTab === idx ? "8px" : "0",
                        },
                      }}
                      iconPosition="start"
                    />
                  );
                }
                return null;
              })}
            </Tabs>
            <div className="div-space-between align-flex-start">
              {currentTab === 2 ? (
                <Stack
                  direction="row"
                  className="mt-12"
                  style={{
                    flexWrap: expandLeverageAndTests ? "wrap" : "nowrap",
                  }}
                >
                  {chipsData?.map((chip: string, idx: number) => {
                    return (
                      <CustomChip
                        key={idx}
                        size="small"
                        label={chip}
                        variant="outlined"
                        className="chipData"
                      />
                    );
                  })}
                </Stack>
              ) : null}
              {/* TODO: uncomment this to enable what if scenarios */}
              {/* <IconButton onClick={expandTabs}>
                <img
                  src={expandLeverageAndTests ? ShrinkButton : ExpandButton}
                  alt="expand-button"
                />
              </IconButton> */}
            </div>
          </div>
          <TabPanel value={currentTab} index={0}>
            {assignedOrTakeOutPartnerId &&
            loanType === "InvestorDSCR" &&
            dynamicConfig?.allowPartnerSelection &&
            orgFundingType !== "table_funding" &&
            loanDetails?.sizingType !== SizingTypeValue.QUICK_LOAN_SIZING ? (
              <div>
                <Disclaimer
                  boldText={""}
                  children={<span>{t("bestPricingForTheApplication")}</span>}
                  customClass="disclaimerBox"
                  showClose={true}
                  isRatesAsOff={false}
                />
                <div
                  style={{
                    backgroundColor: "#6750A4",
                    color: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "8px 10px",
                    borderRadius: "6px",
                    marginTop: "8px",
                  }}
                >
                  <span style={{ fontWeight: 500 }}>Program Selected:</span>
                  <span style={{ fontWeight: 600 }}>
                    {DSCROrgPartnerMapping[assignedOrTakeOutPartnerId]}
                  </span>
                </div>
              </div>
            ) : null}
            <Grid container className="mt-12">
              {failedFinalRate && !buyDownOptions?.length ? (
                <div className="p-12">
                  <div className="finalPrice-err-msg p-12 div-space-between">
                    <img src={InfoErrorIcon} alt="info-svg" className="mr-12" />
                    <p className="fs-14">{t("finalPriceCalclationError")}</p>
                  </div>
                </div>
              ) : null}
              {isRiskPricing && (
                <div className="p-12 d-flex w-100">
                  <div className="risk-price-msg p-12">
                    <img
                      src={InfoMsgIcon}
                      alt="info-svg"
                      className="mr-12 h-20"
                    />
                    <div className="fs-14">{t(isRiskPricing)}</div>
                  </div>
                </div>
              )}
              {renderRightPanel()}
            </Grid>
          </TabPanel>
          {[0, 3].includes(currentTab) &&
          Object.keys(leverageAndPricingData ?? {})?.length &&
          buyDownOptions?.length ? (
            <BuyDownOptionsWrapper
              handleSaveApplication={handleSaveApplication}
            />
          ) : null}
          {[0].includes(currentTab) &&
          loanDetails?.sizingType === SizingTypeValue.QUICK_LOAN_SIZING ? (
            <Disclaimer
              boldText={t("disclaimer")}
              children={<span>{t("mmtcDisclaimerTexts")}</span>}
              customClass="disclaimerBox"
              showClose={false}
            />
          ) : null}
          <TabPanel value={currentTab} index={1}>
            <BridgeLoanResultTab
              tabData={testTabData}
              isExpanded={isExpanded}
              expandLeverageAndTests={expandLeverageAndTests}
              setIsExpanded={setIsExpanded}
              loanDetails={loanDetails}
              isLoanDSCR={loanType === "InvestorDSCR"}
              ratesAsOfDate={ratesAsOfDate}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <PartnerSelectionView
              setSnackBarMsg={setSnackBarMsg}
              setShowSnackBar={setShowSnackBar}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
            />
          </TabPanel>
          {currentTab === 3 && buyDownOptions?.length ? (
            <RequestWaiverWrapper
              handleSaveApplication={handleSaveApplication}
            />
          ) : null}
        </div>
      </div>
      {openWarningBeforeSubmit ? (
        <CustomModal
          open={openWarningBeforeSubmit}
          handleClose={setOpenWarningBeforeSubmit}
          children={renderSubmitApplicationModal()}
          className={"saveApplication"}
        />
      ) : null}
      {showSnackBar ? renderSnackBar() : null}
      {condoEligibility ? (
        <CustomModal
          open={condoEligibility}
          handleClose={() => setCondoEligibility(false)}
          children={CondoEligibiltyModalContent()}
          className={"condoEligibility"}
        />
      ) : null}
    </div>
  );
}
