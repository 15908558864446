export const devConfigBplSizer = {
  ViteAppApiHostUrl: "https://api-dev.toorakcapital.info",
  ViteAppRealms:
    "https://auth-dev.gcp.roemanu.io/realms/bplsizer/protocol/openid-connect",
  ViteAppClientId: "login",
  ViteAppClientOriginatorId: "",
  ViteAppHostRedirectUri: "http://bplsizer.localhost:3000",
  ViteAppKcRealm: "bplsizer",
  ViteAppTcRedirect: "https://dev.toorakcapital.info/loans/list?tab=3",
  ViteAppTcLogo:
    "https://storage.googleapis.com/toorak-image-template/tooraklogo.png",
  allowPartnerSelection: true,
  allowEditAfterSubmit: false,
};

export const qaConfigBplSizer = {
  ViteAppApiHostUrl: "https://api-qa.toorakcapital.info",
  ViteAppRealms:
    "https://auth-qa.gcp.roemanu.io/realms/bplsizer/protocol/openid-connect",
  ViteAppClientId: "login",
  ViteAppHostRedirectUri: "https://qa.bplsizer.com",
  ViteAppKcRealm: "bplsizer",
  ViteAppTcRedirect: "https://qa.toorakcapital.info/loans/list?tab=3",
  ViteAppTcLogo:
    "https://storage.googleapis.com/toorak-image-template/Toorak_Logo.svg",
  clientId: "",
  partyId: "",
  allowPartnerSelection: true,
  allowEditAfterSubmit: false,
};

export const uatConfigBplSizer = {
  ViteAppApiHostUrl: "https://api-uat.toorakcapital.info",
  ViteAppRealms:
    "https://auth-uat.gcp.roemanu.io/realms/bplsizer/protocol/openid-connect",
  ViteAppClientId: "login",
  ViteAppHostRedirectUri: "https://uat.bplsizer.com",
  ViteAppKcRealm: "bplsizer",
  ViteAppTcRedirect: "https://uat.toorakcapital.info/loans/list?tab=3",
  ViteAppTcLogo:
    "https://storage.googleapis.com/toorak-image-template/Toorak_Logo.svg",
  clientId: "",
  partyId: "",
  allowPartnerSelection: true,
};

export const prodConfigBplSizer = {
  ViteAppApiHostUrl: "https://api.toorakcapital.info",
  ViteAppRealms:
    "https://auth-gcp.roemanu.io/realms/bplsizer/protocol/openid-connect",
  ViteAppClientId: "login",
  ViteAppHostRedirectUri: "https://bplsizer.com",
  ViteAppKcRealm: "bplsizer",
  ViteAppTcRedirect: "https://toorakcapital.info/loans/list?tab=3",
  ViteAppTcLogo:
    "https://storage.googleapis.com/toorak-image-template/Toorak_Logo.svg",
  clientId: "1f114ff5-82f4-4d9e-9f3f-74ee2c5376ca",
  partyId: "",
  allowPartnerSelection: true,
  supportEmail: "techsupport@toorakcapital.com",
};

export const devConfigMerchants = {
  ViteAppApiHostUrl: "https://dev.toorakcapital.info/api",
  ViteAppRealms:
    "https://auth-dev.gcp.roemanu.io/realms/merchants-dev/protocol/openid-connect",
  ViteAppClientId: "onlinesizer-login",
  ViteAppClientOriginatorId: "",
  ViteAppHostRedirectUri: "http://online-sizer.localhost:3000",
  ViteAppKcRealm: "merchants-dev",
  ViteAppTcRedirect: "https://dev.toorakcapital.info/loans/list?tab=3",
  ViteAppTcLogo: "https://cdn.roemanu.io/los/mmtc/emails/mmtc-logo.jpg",
  clientId: "7fb6d919-c125-4398-8fd0-95370525ba90",
  partyId: "54a7fc34-19bc-4734-bba9-8c0b275c0ffc",
  disclaimer:
    "The calculations are estimates and not guaranteed. The calculator uses the current guidelines and rate sheets. Click on the icon to view the Rate Sheet",
  rateSheetIcon:
    "https://codeskulptor-demos.commondatastorage.googleapis.com/descent/red_stone_23_23.png",
  rateSheetLink:
    "https://storage.googleapis.com/toorak-image-template/DSCR%20and%20Ratesheet/DSCR%20Rate%20Sheet%202024-03-07.pdf",
  allowPartnerSelection: true,
  fileUploadUrl:
    "https://api.dev.merchantsmtg.info/bff/document/generatePreSignedUrl",
  fileDownloadUrl: "https://api.dev.merchantsmtg.info/bff/documents/download",
  allowEditAfterSubmit: true,
  showSizingType: true,
};

export const qaConfigMerchants = {
  ViteAppApiHostUrl: "https://api-qa.toorakcapital.info",
  ViteAppRealms:
    "https://auth-qa.gcp.roemanu.io/realms/merchants-qa/protocol/openid-connect",
  ViteAppClientId: "login",
  ViteAppHostRedirectUri: "https://qa.online-sizer.merchantsmtg.info",
  ViteAppKcRealm: "merchants-qa",
  ViteAppTcRedirect: "https://qa.toorakcapital.info/loans/list?tab=3",
  ViteAppTcLogo: "https://cdn.roemanu.io/los/mmtc/emails/mmtc-logo.jpg",
  clientId: "4e5e0727-7854-4428-898b-fba5f0b2985e",
  partyId: "4a3858b7-5916-4da7-a4c0-82715f1dbf69",
  disclaimer:
    "The calculations are estimates and not guaranteed. The calculator uses the current guidelines and rate sheets. Click on the icon to view the Rate Sheet",
  rateSheetLink:
    "https://storage.googleapis.com/toorak-image-template/DSCR%20and%20Ratesheet/DSCR%20Rate%20Sheet%202024-03-07.pdf",
  allowPartnerSelection: true,
  fileUploadUrl:
    "https://api.qa.merchantsmtg.info/bff/document/generatePreSignedUrl",
  fileDownloadUrl: "https://api.qa.merchantsmtg.info/bff/documents/download",
  allowEditAfterSubmit: true,
  showSizingType: true,
};

export const uatConfigMerchants = {
  ViteAppApiHostUrl: "https://api-uat.toorakcapital.info",
  ViteAppRealms:
    "https://auth-uat.gcp.roemanu.io/realms/merchants-uat/protocol/openid-connect",
  ViteAppClientId: "login",
  ViteAppHostRedirectUri: "https://uat.online-sizer.merchantsmtg.info",
  ViteAppKcRealm: "merchants-uat",
  ViteAppTcRedirect: "https://uat.toorakcapital.info/loans/list?tab=3",
  ViteAppTcLogo: "https://cdn.roemanu.io/los/mmtc/emails/mmtc-logo.jpg",
  clientId: "c73040b6-de14-4681-936d-896cacf26e6f",
  partyId: "1bb6f1b1-cbb8-4ff0-9583-0f588e483712",
  disclaimer:
    "The calculations are estimates and not guaranteed. The calculator uses the current guidelines and rate sheets. Click on the icon to view the Rate Sheet",
  rateSheetLink:
    "https://storage.googleapis.com/toorak-image-template/DSCR%20and%20Ratesheet/DSCR%20Rate%20Sheet.pdf",
  allowPartnerSelection: true,
  allowEditAfterSubmit: true,
  fileDownloadUrl: "https://api.uat.merchantsmtg.info/bff/documents/download",
  fileUploadUrl:
    "https://api.uat.merchantsmtg.info/bff/document/generatePreSignedUrl",
  showSizingType: true,
};

export const prodConfigMerchants = {
  ViteAppApiHostUrl: "https://api.toorakcapital.info",
  ViteAppRealms:
    "https://auth-gcp.roemanu.io/realms/merchants/protocol/openid-connect",
  ViteAppClientId: "login",
  ViteAppHostRedirectUri: "https://online-sizer.merchantsmtg.info",
  ViteAppKcRealm: "merchants",
  ViteAppTcRedirect: "https://toorakcapital.info/loans/list?tab=3",
  clientId: "27a76112-40ef-43e1-8792-c7954fcdc682",
  partyId: "d6d59d03-a2ef-4123-a010-2764022258f1",

  ViteAppTcLogo: "https://cdn.roemanu.io/los/mmtc/emails/mmtc-logo.jpg",
  disclaimer:
    "The calculations are estimates and not guaranteed. The calculator uses the current guidelines and rate sheets.",
  allowPartnerSelection: true,
  allowEditAfterSubmit: true,
  fileDownloadUrl: "https://api.merchantsmtg.info/bff/documents/download",
  fileUploadUrl:
    "https://api.merchantsmtg.info/bff/document/generatePreSignedUrl",
  showSizingType: true,
};

export const getConfig = (client: string, env: string) => {
  switch (client) {
    case "bplsizer":
      switch (env) {
        case "dev":
          return devConfigBplSizer;
        case "qa":
          return qaConfigBplSizer;
        case "uat":
          return uatConfigBplSizer;
        case "prod":
          return prodConfigBplSizer;
      }
    case "merchants":
      switch (env) {
        case "dev":
          return devConfigMerchants;
        case "qa":
          return qaConfigMerchants;
        case "uat":
          return uatConfigMerchants;
        case "prod":
          return prodConfigMerchants;
      }
  }
};
