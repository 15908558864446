import React, { createContext, useState } from "react";

import { ContextProps } from "../types/rootContextTypes";
import { ObjectType } from "../types/tableTypes";
import { LocationSuggestion, ZipCodeSuggestion } from "../types/fieldTypes";

import { sampleCondoEligibility } from "../pages/Loans/v1/Common";

const LoansContext = createContext<any>(null);

export const unitInformation: any = [{}];
export const propertyDetails = [
  {
    propertyLocation: {},
    propertyInformation: {},
    propertyEconomics: { decliningMarkets: "No" },
    unitInformation,
  },
];

export const intialDetailedLoanSizingState = {
  borrowerInformation: {},
  loanInformation: { loanTerm: "360" },
  loanEconomics: {
    prepaymentPenaltyTypeMonths: 0,
    prepayPenaltyType: 0,
  },
  propertyDetails,
};

const LoansProvider: React.FC<ContextProps> = ({ children }) => {
  const [loanFormData, setLoanFormData] = useState<ObjectType>({});
  const [autoCalculatedFields, setAutoCalculatedFields] = useState<ObjectType>(
    {}
  );
  const [loanDetailsConfig, setLoanDetailsConfig] = useState<any>({});
  const [propertyDetailsConfig, setPropertyDetailsConfig] = useState<any>([]);
  const [borrowerInformationConfig, setBorrowerInformationConfig] =
    useState<any>({});
  const [loanDetails, setLoanDetails] = useState<any>({});
  const [propertyDetails, setPropertyDetails] = useState<any>([]);
  const [defaultSelectedDataView, setDefaultSelectedDataView] =
    useState<string>("");
  const [expandLeverageAndTests, setExpandLeverageAndTests] = useState(false);
  const [expandAvailableProgramsView, setExpandAvailableProgramsView] =
    useState(false);
  const [isApplicationSubmitted, setIsApplicationSubmitted] =
    useState<boolean>(true);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [leverageTestsConfig, setLeverageTestsConfig] = useState<any>([]);
  const [loanFormErrors, setLoanFormErrors] = useState<ObjectType>({});
  const [propFormErrors, setPropFormErrors] = useState<ObjectType[]>([]);
  const [leverageAndPricingData, setLeverageAndPricingData] =
    useState<ObjectType>({});
  const [propertyDetailTemplate, setPropertyDetailsTemplate] =
    useState<ObjectType>({});
  const [propertyDetailsConfigTemplate, setPropertyDetailsConfigTemplate] =
    useState<ObjectType>({});
  const [unitInfoConfigTemplate, setUnitInfoConfigTemplate] =
    useState<ObjectType>({});
  const [unitInfoTemplate, setUnitInfoTemplate] = useState<ObjectType>([]);
  const [testTabData, setTestTabData] = useState<ObjectType[]>([]);
  const [autoCalculatedLoanResults, setAutoCalculatedLoanResults] =
    useState<ObjectType>({});
  const [autoCalculatedPropResults, setAutoCalculatedPropResults] = useState<
    ObjectType[]
  >([]);
  const [condoEligibilityConfig, setCondoEligibilityConfig] = useState<any>({});
  const [condoEligibility, setCondoEligibility] = useState<boolean>(false);
  const [condoEligibilityFormData, setCondoEligibilityFormData] =
    useState<ObjectType>(sampleCondoEligibility);
  const [addressInputValue, setAddressInputValue] = useState<string>("");
  const [addressOptions, setAddressOptions] = useState<LocationSuggestion[]>(
    []
  );
  const [zipCodeInputValue, setZipCodeInputValue] = useState("");
  const [zipCodeOptions, setZipCodeOptions] = useState<ZipCodeSuggestion[]>([]);
  const [failedFinalRate, setFailedFinalRate] = useState<boolean>(false);
  const [fetchData, setFetchData] = useState<ObjectType>({});
  const [ratesAsOfDate, setRatesAsOfDate] = useState<string>("");
  const [debouncedValue, setDebouncedValue] = useState<any>(null);
  const [noAssignedPartner, setNoAssignedPartner] = useState<string>("");
  const [assignedPartner, setAssignedPartner] = useState<string>("");
  const [isUnitExpanded, setIsUnitExpanded] = useState<ObjectType>({});
  const [chipsData, setChipsData] = useState<string[]>();
  const [eligiblePartnerResults, setEligiblePartnerResults] =
    useState<ObjectType>({});
  const [partnersResults, setPartnersResults] = useState<ObjectType>({});
  const [loansApiResponse, setLoansApiResponse] = useState<any>({});
  const [rulesResponse, setRulesResponse] = useState<any>({});
  const [criticalTests, setCriticalTests] = useState<any>({});
  const [templateResponseData, setTemplateResponseData] = useState<ObjectType>(
    []
  );
  // V2
  const [selectedSizingType, setSizingType] = useState<string>("");
  const [quickLoanSizingData, setQuickLoanSizingData] = useState<any>({});
  const [detailedLoanSizingData, setDetailedLoanSizingData] = useState<any>(
    intialDetailedLoanSizingState
  );
  const [view, setView] = useState("");
  const [fieldConfig, setFieldConfig] = useState<any>({});
  const [detailedLoanSizingErrors, setDetailedLoanSizingErrors] = useState<any>(
    {}
  );
  const [buyDownOptions, setBuyDownOptions] = useState<any>([]);
  const [currentSelectedBuyDownOption, setCurrentSelectedBuyDownOption] =
    useState<any>({});
  const [newDebouncedValue, setNewDebouncedValue] = useState<any>(null);
  const [currentSelectedInterestRate, setCurrentSelectedInterestRate] =
    useState({});
  const [latestTermSheetVersion, setLatestTermSheetVersion] = useState<
    number | string
  >("");
  const [priceExceptionStatus, setPriceExceptionStatus] = useState<
    string | undefined | null
  >("");

  return (
    <LoansContext.Provider
      value={{
        chipsData,
        loanFormData,
        borrowerInformationConfig,
        loanDetailsConfig,
        propertyDetailsConfig,
        loanDetails,
        propertyDetails,
        defaultSelectedDataView,
        expandLeverageAndTests,
        isApplicationSubmitted,
        showSnackBar,
        leverageTestsConfig,
        loanFormErrors,
        propFormErrors,
        leverageAndPricingData,
        propertyDetailTemplate,
        propertyDetailsConfigTemplate,
        unitInfoConfigTemplate,
        unitInfoTemplate,
        testTabData,
        autoCalculatedLoanResults,
        autoCalculatedPropResults,
        condoEligibilityConfig,
        condoEligibility,
        condoEligibilityFormData,
        addressInputValue,
        addressOptions,
        zipCodeInputValue,
        zipCodeOptions,
        failedFinalRate,
        fetchData,
        ratesAsOfDate,
        debouncedValue,
        noAssignedPartner,
        assignedPartner,
        isUnitExpanded,
        autoCalculatedFields,
        expandAvailableProgramsView,
        eligiblePartnerResults,
        partnersResults,
        loansApiResponse,
        rulesResponse,
        criticalTests,
        templateResponseData,
        view,
        selectedSizingType,
        quickLoanSizingData,
        detailedLoanSizingData,
        fieldConfig,
        detailedLoanSizingErrors,
        buyDownOptions,
        currentSelectedBuyDownOption,
        newDebouncedValue,
        currentSelectedInterestRate,
        latestTermSheetVersion,
        priceExceptionStatus,
        // setState functions
        setChipsData,
        setLoanFormData,
        setLoanDetailsConfig,
        setPropertyDetailsConfig,
        setBorrowerInformationConfig,
        setLoanDetails,
        setPropertyDetails,
        setDefaultSelectedDataView,
        setExpandLeverageAndTests,
        setIsApplicationSubmitted,
        setShowSnackBar,
        setLeverageTestsConfig,
        setLoanFormErrors,
        setPropFormErrors,
        setLeverageAndPricingData,
        setPropertyDetailsTemplate,
        setPropertyDetailsConfigTemplate,
        setUnitInfoConfigTemplate,
        setUnitInfoTemplate,
        setTestTabData,
        setAutoCalculatedLoanResults,
        setAutoCalculatedPropResults,
        setCondoEligibilityConfig,
        setCondoEligibility,
        setCondoEligibilityFormData,
        setAddressInputValue,
        setAddressOptions,
        setZipCodeInputValue,
        setZipCodeOptions,
        setFailedFinalRate,
        setFetchData,
        setRatesAsOfDate,
        setDebouncedValue,
        setNoAssignedPartner,
        setAssignedPartner,
        setIsUnitExpanded,
        setAutoCalculatedFields,
        setExpandAvailableProgramsView,
        setEligiblePartnerResults,
        setPartnersResults,
        setLoansApiResponse,
        setRulesResponse,
        setCriticalTests,
        setTemplateResponseData,
        setView,
        setSizingType,
        setQuickLoanSizingData,
        setDetailedLoanSizingData,
        setFieldConfig,
        setDetailedLoanSizingErrors,
        setNewDebouncedValue,
        setCurrentSelectedBuyDownOption,
        setBuyDownOptions,
        setCurrentSelectedInterestRate,
        setLatestTermSheetVersion,
        setPriceExceptionStatus,
      }}
    >
      {children}
    </LoansContext.Provider>
  );
};

export { LoansContext, LoansProvider };
