import getVersionedFields from "..";
import { SizingTypeValue } from "../../../../types/enums";
import { isFalsyButNotZero } from "../../../../utils/Validations";

const DSCR = {
  id: "dscr",
  label: "dscr",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: () => true,
  isExpandedView: false,
  isAutoCalculated: (data: any) => data?.sizingType === SizingTypeValue.QUICK_LOAN_SIZING,
  fieldInfo: "This is auto-calculated based on the loan amount, income generated by the property, and the total of the insurances and taxes. It measures the property's ability to cover its debt obligations.",
};

const loanOutputFields: any = {
  DSCR,
};

export function getLoanOutputFields(
  productType: string,
  client: string,
  section: string,
  sizingType?: string
) {
  const finalConfigToBeReturned: any = [];
  const vFields: any = getVersionedFields(productType);
  let configToBeReturned: any = vFields?.[client]?.["inputFields"]?.[section];

  if (sizingType) {
    configToBeReturned = configToBeReturned?.[sizingType];
  }

  configToBeReturned?.map((field: string) => {
    if (loanOutputFields[field]) {
      finalConfigToBeReturned.push(loanOutputFields[field]);
    }
  });

  return finalConfigToBeReturned;
}
