import { SizingTypeValue } from "../../../types/enums";
import TermSheetCell from "../../termSheet/TermSheetCell";

export const TableColumns = [
  {
    title: "Evaluation Id",
    id: "evaluationId",
    hidden: () => false,
    tbField: "evaluationId",
    renderCustom: (
      data: any,
      styles: any,
      handleDropdownSelection: any,
      onLinkClick: any
    ) => {
      return (
        <span style={{ color: "blue", cursor: "pointer" }}>
          <a
            href={onLinkClick(data.loanType, data.loanId, data.productType)}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "blue",
            }}
            className="eval-link"
          >
            {data.evaluationId}
          </a>
        </span>
      );
    },
    width: 120,
  },
  {
    title: "Application Name",
    id: "applicationName",
    hidden: () => false,
    tbField: "applicationName",
    width: 200,
    renderCustom: (data: any) => {
      return (
        <div
          style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          title={data?.applicationName}
        >
          {data?.applicationName}
        </div>
      );
    },
  },
  {
    title: "Loan Type",
    id: "loanType",
    hidden: () => false,
    showDropDownFilter: true,
    tbField: "productType",
    width: 136,
    boxProps: {
      ml: "auto",
      lineHeight: 0,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  {
    title: "Loan Amount",
    id: "loanAmount",
    hidden: () => false,
    tbField: "loanAmount",
    width: 120,
  },
  {
    title: "Interest Rate",
    id: "interestRate",
    hidden: () => false,
    tbField: "interestRate",
    width: 120,
  },
  {
    title: "Created On",
    id: "createdOn",
    hidden: () => false,
    tbField: "createdOn",
    width: 120,
  },
  {
    title: "Created By",
    id: "createdBy",
    showDropDownFilter: true,
    hidden: (data: any) =>
      data?.sizingType === SizingTypeValue.QUICK_LOAN_SIZING ||
      data?.isLoanOfficer,
    tbField: "createdBy",
    width: 180,
    renderCustom: (data: any) => {
      return (
        <div
          style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          title={data?.createdBy}
        >
          {data?.createdBy}
        </div>
      );
    },
    boxProps: {
      ml: "auto",
      lineHeight: 0,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  {
    title: "Price Exception",
    id: "waiverStatus",
    hidden: (data: any) => !data?.merchants,
    showDropDownFilter: true,
    tbField: "waiverStatus",
    width: 220,
    boxProps: {
      ml: "auto",
      lineHeight: 0,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  {
    title: "Rate as of",
    id: "ratesAsOf",
    hidden: () => false,
    showDateRangeFilter: true,
    tbField: "ratesAsOf",
    width: 136,
    boxProps: {
      ml: "auto",
      lineHeight: 0,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  {
    title: "Term Sheet",
    id: "termSheet",
    hidden: (data: any) => !data.termSheet,
    tbField: "termSheet",
    width: 160,
    renderCustom: (data: any) => {
      const label = data?.termSheet;
      return (
        <div>
          {label === "Not Generated" ? (
            <div
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
              title={label}
            >
              {label}
            </div>
          ) : (
            <div>
              <TermSheetCell label={label} rowData={data} />
            </div>
          )}
        </div>
      );
    },
  },
  {
    title: "Street Address",
    id: "streetAddress",
    hidden: (data: any) =>
      data?.sizingType === SizingTypeValue.QUICK_LOAN_SIZING,
    tbField: "streetAddress",
    width: 260,
    renderCustom: (data: any) => {
      return (
        <div
          style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          title={data?.streetAddress}
        >
          {data?.streetAddress}
        </div>
      );
    },
  },
  {
    title: "City",
    id: "city",
    hidden: () => false,
    tbField: "city",
    width: 160,
  },
  {
    title: "Postal",
    id: "postal",
    hidden: () => false,
    tbField: "postal",
    width: 120,
  },
  // {
  //   title: "Sizing Stage",
  //   id: "sizingType",
  //     hidden: (data: any)=> !data?.merchants,
  //   showDropDownFilter: true,
  //   tbField: "sizingType",
  //   width: 160,
  //   boxProps: {
  //     ml: "auto",
  //     lineHeight: 0,
  //     display: "flex",
  //     flexDirection: "row",
  //     alignItems: "center",

  //   },
  // },
];
