import { SizingTypeValue } from "../../../types/enums";
import { ObjectType } from "../../../types/tableTypes";

export const MaxLoanAmount = {
  id: "maxLoanAmount",
  subText: "maxLoanAmount",
  label: "maxLoanAmount",
  gridSpace: 5,
  width: "100%",
};

export const FinalRate = {
  id: "finalRate",
  subText: "finalRate",
  label: "finalRate",
  gridSpace: 3,
  width: "100%",
};

export const PriceAdjustment = {
  id: "priceAdjustment",
  subText: "priceAdjustment",
  label: "priceAdjustment",
  gridSpace: 3,
  width: "48%",
};

export const BaseRate = {
  id: "baseRate",
  subText: "baseRate",
  label: "baseRate",
  gridSpace: 3,
  width: "48%",
};

export const RiskBucket = {
  id: "riskBucket",
  subText: "riskBucket",
  label: "riskBucket",
  gridSpace: 2,
  width: "48%",
};

export const RiskScore = {
  id: "riskScore",
  subText: "riskScore",
  label: "riskScore",
  gridSpace: 2,
  hide: (data: ObjectType) => {
    return data?.riskScoreCards?.riskBucket &&
      data?.riskScoreCards?.riskScoresVersion === "1.0"
      ? false
      : true;
  },
  width: "48%",
};

export const PD = {
  id: "pd",
  subText: "pd",
  label: "pd",
  gridSpace: 2,
  hide: (data: ObjectType) => {
    return data?.riskScoreCards?.riskBucket &&
      data?.riskScoreCards?.riskScoresVersion === "2.0"
      ? false
      : true;
  },
  width: "48%",
};

export const FinalPrice = {
  id: "finalPrice",
  subText: "finalPrice",
  label: "finalPrice",
  gridSpace: 3,
  width: "48%",
};

export const FinalPriceTable = {
  id: "finalPriceTable",
  subText: "finalPriceTable",
  label: "finalPriceTable",
  headerClass: "final-price-div",
  type: "table",
  tableBody: [],
  fullView: true,
  gridSpace: 3,
};
export const InterestRate = {
  id: "interestRate",
  subText: "interestRate",
  label: "interestRate",
  gridSpace: 4,
  width: "48%",
};

export const DSCR = {
  id: "dscr",
  subText: "dscr",
  label: "dscr",
  gridSpace: 4,
  width: "48%",
};

export const prepaymentPenaltyMonthsField = {
  id: "prepaymentPenaltyTypeMonths",
  subText: "prepaymentPenaltyTypeMonths",
  label: "prepaymentPenaltyTypeMonths",
  gridSpace: 4,
  width: "48%",
  textMaxWidth: "100%",
};

export const prepaymentPenaltyField = {
  id: "prepayPenaltyType",
  subText: "prepayPenaltyType",
  label: "prepayPenaltyType",
  gridSpace: 3,
  width: "48%",
  textMaxWidth: "100%",
};

export const originationPointsField = {
  id: "originationPoints",
  subText: "originationPoints",
  label: "originationPoints",
  gridSpace: 3,
  width: "48%",
  textMaxWidth: "100%",
};

const dscrLeverageTableHeader = [
  { label: "", headerClassName: "" },
  {
    label: "ltvAsIs",
    headerClassName: "black-table-header-text bordered-table-cell",
  },
  {
    label: "ltpp",
    headerClassName: "black-table-header-text bordered-table-cell",
  },
];
const bridgeGeneralLeverageTableHeader = [
  { label: "", headerClassName: "black-table-header-text bordered-table-cell" },
  {
    label: "ltvAsIs",
    headerClassName: "black-table-header-text bordered-table-cell",
  },
  {
    label: "ltvARV",
    headerClassName: "black-table-header-text bordered-table-cell",
  },
  {
    label: "ltc",
    headerClassName: "black-table-header-text bordered-table-cell",
  },
];
const bridgeGroundUpLeverageTableHeader = [
  { label: "", headerClassName: "black-table-header-text bordered-table-cell" },
  {
    label: "ltvAsIs",
    headerClassName: "black-table-header-text bordered-table-cell",
  },
  {
    label: "ltvARV",
    headerClassName: "black-table-header-text bordered-table-cell",
  },
  {
    label: "ltc",
    headerClassName: "black-table-header-text bordered-table-cell",
  },
  {
    label: "blendedLtc",
    headerClassName: "black-table-header-text bordered-table-cell",
  },
  {
    label: "blendedLtcwithFundedIR",
    headerClassName: "black-table-header-text bordered-table-cell",
  },
];

export const Leverages: ObjectType = {
  id: "leverages",
  label: "leverages",
  type: "table",
  headerClass: "div-space-between",
  fullView: true,
  tableHeader: [],
  tableBody: [
    {
      id: "max",
      label: "max",
      borderColor: "safronBorder",
    },
    {
      id: "adjustedMax",
      label: "adjustedMax",
      borderColor: "greenBorder",
    },
    {
      id: "requested",
      label: "requested",
      borderColor: "blueBorder",
    },
  ],
};

const dscrLeverageTableBody = [
  {
    id: "geographyStateAdjustment",
    label: "geographyStateAdjustment",
  },
  {
    id: "geographyCounty",
    label: "geographyCounty",
  },
];

const bridgeLeverageTableBody = [
  {
    id: "loanBalanceAndDSCR",
    label: "loanBalanceAndDSCR",
  },
  {
    id: "brokerFees",
    label: "brokerFees",
  },
  {
    id: "foreignNational",
    label: "foreignNational",
  },
  {
    id: "medianHomePriceDecline",
    label: "medianHomePriceDecline",
  },
  {
    id: "propertyValueVsMedianZipCodeValue",
    label: "propertyValueVsMedianZipCodeValue",
  },
];

export const LeveragesAdjustments = {
  id: "leverageAdjustment",
  label: "leverageAdjustment",
  type: "table",
  headerClass: "div-space-between",
  headerBGClass: "table-lvgAdj-background",
  fullView: true,
  tableHeader: [],
  tableBody: [],
};

export const Profitability = {
  id: "profitability",
  label: "profitability",
  subText: "profitability",
  width: "48%",
};

export const RentYield = {
  id: "rentYield",
  label: "rentYield",
  subText: "rentYield",
  width: "48%",
};

const bridgeRehab2Plus1Data = {
  tableHeader: ["", "initProceeds", "rehabAmount", "totalMaxLoanAmount"],
  tableBody: [
    {
      id: "max",
      label: "max",
    },
    {
      id: "adjustedMax",
      label: "adjustedMax",
    },
    {
      id: "requested",
      label: "requested",
    },
  ],
};

const bridgeGroundUpData = {
  tableHeader: ["", "requested", "final"],
  tabelBody: [
    "initialProceeds",
    "rehabAmount",
    "initialLoanPlusRehabAmount",
    "interestReserve",
    "totalMaxLoanAmount",
  ],
};

export const Proceeds = {
  id: "proceeds",
  label: "proceeds",
  type: "table",
  fullView: true,
  tableHeader: [],
  tableBody: [],
};

export const MinimumRequiredReserves = {
  id: "minimumRequiredReserves",
  label: "minimumRequiredReserves",
  subText: "minimumRequiredReserves",
  gridSpace: 5,
  width: "48%",
};

export const Dscr = {
  id: "dscr",
  label: "dscr",
  subText: "dscr",
  gridSpace: 3,
  width: "48%",
};

export const FailedTests = {
  id: "failedTests",
  label: "failedTests",
  subText: "failedTests",
  gridSpace: 3,
  width: "48%",
};

export const RatesDisClaimer = {
  id: "rates",
  label: "ratesDisclaimer",
  gridSpace: 12,
  type: "disclaimer",
};

export const RequiredReserves = {
  id: "requiredReserves",
  cards: [MinimumRequiredReserves, Dscr, FailedTests],
};

export const MMTCRequiredReserves = {
  id: "requiredReserves",
  cards: [MinimumRequiredReserves, FailedTests],
};

export const dscrPricing = [
  {
    id: "finalPriceAdjustment",
    label: "totalPricingAdjustment",
  },
  {
    id: "baseFicoLeverageAdj",
    label: "baseFicoLeverageAdj",
  },
  {
    id: "priceAdjPrePayPenalty",
    label: "priceAdjPrePayPenalty",
  },
  {
    id: "priceAdjDSCR",
    label: "priceAdjDSCR",
  },
  {
    id: "priceAdjCashOutRefinance",
    label: "priceAdjCashOutRefinance",
  },
  {
    id: "priceAdjPropertyType",
    label: "priceAdjPropertyType",
  },
  {
    id: "priceAdjLoanBalance",
    label: "priceAdjLoanBalance",
  },
];
export const bridgePricing = [];

export const Pricing: ObjectType = {
  id: "pricing",
  label: "pricing",
  type: "table",
  headerClass: "div-space-between",
  fullView: true,
  tableHeader: [],
  tableBody: [],
};

export const LeverageCardsForDscr = {
  id: "leverageCards",
  cards: [MaxLoanAmount, FinalPrice, InterestRate],
};

export const LeverageCardsForQuickLoanDscr = {
  id: "leverageCards",
  cards: [
    MaxLoanAmount,
    InterestRate,
    FailedTests,
    DSCR,
    prepaymentPenaltyMonthsField,
    prepaymentPenaltyField,
    originationPointsField,
  ],
};

export const LeverageCardsForDetailedDscr = {
  id: "leverageCards",
  cards: [
    MaxLoanAmount,
    // FinalPrice,
    InterestRate,
    DSCR,
    prepaymentPenaltyMonthsField,
    prepaymentPenaltyField,
    originationPointsField,
  ],
};

export const LeverageCardsForBridgeLoan = {
  id: "leverageCards",
  cards: [MaxLoanAmount, FinalRate, PriceAdjustment, BaseRate],
};

export const riskScoreCards = (data: ObjectType) => {
  if (data?.roles?.includes("lat")) {
    return {
      id: "riskScoreCards",
      cards: [RiskBucket, RiskScore, PD],
    };
  } else {
    return {
      id: "riskScoreCards",
      cards: [RiskBucket],
    };
  }
};

export const ProfitAndRentYield = {
  id: "profitAndRentYield",
  cards: [Profitability, RentYield],
};

export const LpForDSCR: any = {
  id: "lpForDSCR",
  components: [],
};

export const LpForBridgeLoan: any = {
  id: "lpForBridge",
  components: [],
};

export const ProceedsTableHeader = [
  "",
  "Initial Proceeds",
  "Rehab Amount",
  "Total Max Loan Amount",
];

export const getLeveragePricingTestsConfig = (
  loanType: string,
  tabName: number,
  sizingType: any = "",
  propType?: string,
  roles?: string[]
) => {
  let leverageCardsForDscr: any = LeverageCardsForDscr;
  if (sizingType === SizingTypeValue.DETAILED_LOAN_SIZING) {
    leverageCardsForDscr = LeverageCardsForDetailedDscr;
  } else if (sizingType === SizingTypeValue.QUICK_LOAN_SIZING) {
    leverageCardsForDscr = LeverageCardsForQuickLoanDscr;
  }

  switch (loanType) {
    case "DSCR":
      {
        switch (tabName) {
          case 0:
            LpForDSCR.components = [
              // add order to group and render in loanpage
              {
                ...leverageCardsForDscr,
                order: 1,
                expandedGridSpace: 6,
                gridSpace: 12,
              },
              {
                ...Leverages,
                tableHeader: dscrLeverageTableHeader,
                order: 1,
                tableClassName: "table",
                expandedGridSpace: 6,
                gridSpace: 12,
              },
            ];
            if (sizingType !== SizingTypeValue.QUICK_LOAN_SIZING) {
              LpForDSCR.components.push(
                {
                  ...LeveragesAdjustments,
                  tableHeader: [
                    {
                      label: "appliedOn",
                      headerClassName: "black-table-header-text",
                    },
                    { label: "ltvAsIs", headerClassName: "purple-header-text" },
                    { label: "ltpp", headerClassName: "purple-header-text" },
                  ],
                  tableBody: [],
                  tableClassName: "tablewithoutheader",
                  order: 2,
                  expandedGridSpace: 6,
                  gridSpace: 12,
                },
                {
                  ...(sizingType !== SizingTypeValue.DETAILED_LOAN_SIZING
                    ? RequiredReserves
                    : MMTCRequiredReserves),
                  order: 1,
                  expandedGridSpace: 6,
                  gridSpace: 12,
                },
                {
                  ...RatesDisClaimer,
                  order: 2,
                  expandedGridSpace: 6,
                  gridSpace: 12,
                }
              );
            }
            if (sizingType === "") {
              LpForDSCR.components.push(
                {
                  ...FinalPriceTable,
                  order: 2,
                  expandedGridSpace: 6,
                  gridSpace: 12,
                  tableClassName: "tablewithoutheader",
                },
                {
                  ...Pricing,
                  tableBody: [],
                  order: 3,
                  expandedGridSpace: 6,
                  gridSpace: 12,
                  tableClassName: "tablewithoutheader",
                }
              );
            }
            return LpForDSCR;
          case 1:
            return "DSCR Test";
          // lpForDSCR.components = [Leverages, MinimumRequiredReserves, Dscr, FailedTests];
        }
      }
      break;
    case "BridgeGroundUp":
      {
        switch (tabName) {
          case 0:
            LpForBridgeLoan.components = [
              { ...LeverageCardsForBridgeLoan, order: 1 },
              {
                ...Leverages,
                tableHeader: bridgeGroundUpLeverageTableHeader,
                order: 1,
                tableClassName: "table",
              },
              {
                ...LeveragesAdjustments,
                tableBody: [],
                order: 2,
                tableClassName: "tablewithoutheader",
              },
              { ...ProfitAndRentYield, order: 2 },
              {
                ...Proceeds,
                tableHeader: [...bridgeGroundUpData.tableHeader],
                tableBody: [...bridgeGroundUpData.tabelBody],
                tableClassName: "table",
                order: 1,
              },
              { ...RatesDisClaimer, order: 2 },
              {
                ...Pricing,
                tableBody: [...bridgePricing],
                order: 2,
                tableClassName: "tablewithoutheader",
              },
            ];
            return LpForBridgeLoan;
          case 1:
            return "Bridge Test";
          default:
            break;
        }
      }
      break;
    case "BridgeRehab":
    case "BridgeFix&Flip":
    case "BridgeStabilized":
      switch (tabName) {
        case 0:
          LpForBridgeLoan.components = [
            { ...LeverageCardsForBridgeLoan, order: 1 },
            { ...riskScoreCards({ roles }), order: 1 },
            {
              ...Leverages,
              tableHeader:
                propType === "GroundUp"
                  ? bridgeGroundUpLeverageTableHeader
                  : bridgeGeneralLeverageTableHeader,
              order: 1,
              tableClassName: "table",
            },
            {
              ...LeveragesAdjustments,
              tableBody: bridgeLeverageTableBody,
              order: 2,
              tableClassName: "tablewithoutheader",
            },
            { ...ProfitAndRentYield, order: 2 },
            {
              ...Proceeds,
              tableHeader: [...bridgeRehab2Plus1Data.tableHeader],
              tableBody: [...bridgeRehab2Plus1Data.tableBody],
              order: 1,
              tableClassName: "table",
            },
            { ...RatesDisClaimer, order: 2 },
            {
              ...Pricing,
              tableBody: [...bridgePricing],
              order: 2,
              tableClassName: "tablewithoutheader",
            },
          ];
          return LpForBridgeLoan;
        case 1:
          return "Bridge Test";
        // LpForBridge.components = [leverageCards, profitAndRentYield];
      }
      break;
    case "BridgeMultiFamily": {
      switch (tabName) {
        case 0:
          LpForBridgeLoan.components = [
            { ...LeverageCardsForBridgeLoan, order: 1 },
            {
              ...Leverages,
              tableHeader:
                propType === "GroundUp"
                  ? bridgeGroundUpLeverageTableHeader
                  : bridgeGeneralLeverageTableHeader,
              order: 1,
              tableClassName: "table",
            },
            {
              ...LeveragesAdjustments,
              tableBody: bridgeLeverageTableBody,
              order: 2,
              tableClassName: "tablewithoutheader",
            },
            { ...ProfitAndRentYield, order: 2 },
            {
              ...Proceeds,
              tableHeader: [...bridgeRehab2Plus1Data.tableHeader],
              tableBody: [...bridgeRehab2Plus1Data.tableBody],
              order: 1,
              tableClassName: "table",
            },
            { ...RatesDisClaimer, order: 2 },
            {
              ...Pricing,
              tableBody: [...bridgePricing],
              order: 2,
              tableClassName: "tablewithoutheader",
            },
          ];
          return LpForBridgeLoan;
        case 1:
          return "Bridge Test";
        // LpForBridge.components = [leverageCards, profitAndRentYield];
      }
    }
  }
};

export const tempLPData = {
  leverageCards: {
    maxLoanAmount: "$3,35,734.88",
    finalRate: "100.250%",
    priceAdjustment: "7.500%",
  },
  profitYield: {
    profitability: "33.33%",
    rentYield: "N/A",
  },
  leverages: [
    ["max", "10%", "20.0%", "30%"],
    ["adjustedMax", "30%", "10%", "40.4%"],
    ["requested", "10%", "100.0%", "130%"],
    ["toorakFinalLeverage", "10%", "10.0%", "10%"],
  ],
  leverageAdjustment: [
    ["loanBalanceAndDSCR", "100%"],
    ["brokerFees", "100%"],
    ["foreignNational", "100%"],
    ["medianHomePriceDecline", "100%"],
    ["propertyValueVsMedianZipCodeValue", "100%"],
  ],
  proceeds: [
    ["max", "10%", "20.0%", "30%"],
    ["adjustedMax", "30%", "10%", "40.4%"],
    ["requested", "10%", "100.0%", "130%"],
  ],
  pricing: [
    ["gucMultiFamilyAdjustment", "10%"],
    ["adjustedToorakRate", "30%"],
    ["leverageAdjustment", "10%"],
  ],
};
